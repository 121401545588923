import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import scss from '../../scss/templates/loadingLayout.module.scss';
import { Loading } from '../molecules/Loading/Loading';

export const LoadingLayout: React.FC = () => {
  return (
    <div className={scss.full_overlay}>
      <Container fluid className={scss.center}>
        <Row>
          <Col md="auto">
            <Loading />
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            <div className={scss.text}>Loading...</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
