/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractDetailsOutputResponse } from '../domains';
/**
 * ContractApi - axios parameter creator
 * @export
 */
export const ContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 契約内容確認取得する
         * @summary 契約内容確認取得
         * @param {string} [id] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDetails: async (id?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/contract-details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractApi - functional programming interface
 * @export
 */
export const ContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 契約内容確認取得する
         * @summary 契約内容確認取得
         * @param {string} [id] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractDetails(id?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContractDetailsOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractDetails(id, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractApi - factory interface
 * @export
 */
export const ContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractApiFp(configuration)
    return {
        /**
         * 契約内容確認取得する
         * @summary 契約内容確認取得
         * @param {string} [id] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractDetails(id?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<Array<ContractDetailsOutputResponse>> {
            return localVarFp.contractDetails(id, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractApi - object-oriented interface
 * @export
 * @class ContractApi
 * @extends {BaseAPI}
 */
export class ContractApi extends BaseAPI {
    /**
     * 契約内容確認取得する
     * @summary 契約内容確認取得
     * @param {string} [id] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractApi
     */
    public contractDetails(id?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ContractApiFp(this.configuration).contractDetails(id, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

