/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {Configuration} from "./configuration";
import {onInstantiated} from "../services/ApiBaseService";

// @ts-ignore
export const BASE_PATH: string = process.env.REACT_APP_BASE_PATH || "http://localhost:8080";

// LocalstorageService
// const localStorageService = LocalStorageService.getService();

/** @export */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|"
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: AxiosRequestConfig;
}


/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
    onInstantiated(this, configuration, basePath, axios);
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError" = "RequiredError";

  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
