import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: ({ label }) => `${label}は必須入力項目です。`,
  },
  string: {
    email: 'メールアドレスの形式が間違っています。',
  },
});

export const schema = yup.object().shape({
  name: yup.string().required().label('名前'),
  mailaddress: yup.string().required().email().label('メールアドレス'),
  telNumber: yup
    .string()
    .matches(/^0[0-9]{9,10}$/, { message: 'ハイフン無しで正しい電話番号を入力してください', excludeEmptyString: true }),
  content: yup.string(),
});
