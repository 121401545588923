import { selectorFamily } from 'recoil';
import { ClientOrgShopListOutputResponse, SelectBoxApi } from '../../api-client';
import { mainBelongListState, subBelongListState } from '../atom/RegisterFormState';
import { RecoilSelectorKeys } from '../RecoilKeys';

export const tobAccountBelongSelectSelector = selectorFamily<ClientOrgShopListOutputResponse | null, string>({
  key: RecoilSelectorKeys.BELONG_SELECT_SELECTOR,
  get:
    (tobAccountIdAndClientAdminId: string) =>
    async ({ get }) => {
      const idArray = tobAccountIdAndClientAdminId.split(':');
      let tobAccountId: string | undefined;
      let clientAllId: string | undefined;
      if (idArray.length > 1 && idArray[0] !== '') {
        // eslint-disable-next-line prefer-destructuring
        tobAccountId = idArray[0];
      }
      if (idArray.length > 1 && idArray[1] !== '') {
        // eslint-disable-next-line prefer-destructuring
        clientAllId = idArray[1];
      }
      // const selectList = get(mainBelongListState(tobAccountId));
      // let list: ClientOrgShopListOutputResponse = { clientList: [], orgList: [], shopList: [], selected: '' };
      const selectApi = new SelectBoxApi();
      const response = selectApi.tobAccountclientShopList('', tobAccountId, 'true', clientAllId);
      return (await response).data;
    },
});
