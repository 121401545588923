import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { CommonApi, ImagePathOutputResponse } from '../../api-client';
import { useParamImage } from '../../hooks/useParamImage';
import { SuspendPromise } from '../../services/SuspendPromise';
import scss from '../../scss/pages/image.module.scss';

const ImagePage: React.FC = () => {
  const history = useHistory();
  const paramImage = useParamImage();
  const ref = useRef<HTMLDivElement>(null);
  const commonApi = new CommonApi();
  const render = paramImage !== undefined;

  const fetchImage = () => {
    return commonApi.imagePath(paramImage.path, paramImage.width, paramImage.height);
  };
  let resource: any;
  if (render) {
    resource = SuspendPromise(fetchImage());
  }
  let image: ImagePathOutputResponse;
  const WrapImage = () => {
    image = resource.read();
    return (
      <div ref={ref} className={scss.image_wrapper}>
        <img src={image.path} style={{ transform: 'none' }} alt="画像" />
      </div>
    );
  };
  return <>{render ? <WrapImage /> : <></>}</>;
};

export default ImagePage;
