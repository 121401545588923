/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ImageConvertBase64FormResponse } from '../domains';
// @ts-ignore
import { ImageConvertBase64OutputResponse } from '../domains';
// @ts-ignore
import { ImagePathOutputResponse } from '../domains';
/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {string} path 
         * @param {string} [width] 
         * @param {string} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagePath: async (path: string, width?: string, height?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('imagePath', 'path', path)
            const localVarPath = `/api/image-path`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {Array<ImageConvertBase64FormResponse>} imageConvertBase64FormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagePathList: async (imageConvertBase64FormResponse: Array<ImageConvertBase64FormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageConvertBase64FormResponse' is not null or undefined
            assertParamExists('imagePathList', 'imageConvertBase64FormResponse', imageConvertBase64FormResponse)
            const localVarPath = `/api/image-path-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageConvertBase64FormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メール開封イメージ取得する
         * @summary メール開封イメージ取得
         * @param {string} tobAccountId 
         * @param {string} [applyId] 応募ID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailOpenImg: async (tobAccountId: string, applyId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tobAccountId' is not null or undefined
            assertParamExists('mailOpenImg', 'tobAccountId', tobAccountId)
            const localVarPath = `/api/public/mail-open-img/{tobAccountId}`
                .replace(`{${"tobAccountId"}}`, encodeURIComponent(String(tobAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applyId !== undefined) {
                localVarQueryParameter['applyId'] = applyId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {string} path 
         * @param {string} [width] 
         * @param {string} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagePath(path: string, width?: string, height?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImagePathOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagePath(path, width, height, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {Array<ImageConvertBase64FormResponse>} imageConvertBase64FormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imagePathList(imageConvertBase64FormResponse: Array<ImageConvertBase64FormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageConvertBase64OutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imagePathList(imageConvertBase64FormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メール開封イメージ取得する
         * @summary メール開封イメージ取得
         * @param {string} tobAccountId 
         * @param {string} [applyId] 応募ID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailOpenImg(tobAccountId: string, applyId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailOpenImg(tobAccountId, applyId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {string} path 
         * @param {string} [width] 
         * @param {string} [height] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagePath(path: string, width?: string, height?: string, options?: any): AxiosPromise<ImagePathOutputResponse> {
            return localVarFp.imagePath(path, width, height, options).then((request) => request(axios, basePath));
        },
        /**
         * 画像パスを取得する
         * @summary 画像パス取得
         * @param {Array<ImageConvertBase64FormResponse>} imageConvertBase64FormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imagePathList(imageConvertBase64FormResponse: Array<ImageConvertBase64FormResponse>, options?: any): AxiosPromise<Array<ImageConvertBase64OutputResponse>> {
            return localVarFp.imagePathList(imageConvertBase64FormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * メール開封イメージ取得する
         * @summary メール開封イメージ取得
         * @param {string} tobAccountId 
         * @param {string} [applyId] 応募ID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailOpenImg(tobAccountId: string, applyId?: string, allClient?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.mailOpenImg(tobAccountId, applyId, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 画像パスを取得する
     * @summary 画像パス取得
     * @param {string} path 
     * @param {string} [width] 
     * @param {string} [height] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public imagePath(path: string, width?: string, height?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).imagePath(path, width, height, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画像パスを取得する
     * @summary 画像パス取得
     * @param {Array<ImageConvertBase64FormResponse>} imageConvertBase64FormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public imagePathList(imageConvertBase64FormResponse: Array<ImageConvertBase64FormResponse>, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).imagePathList(imageConvertBase64FormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メール開封イメージ取得する
     * @summary メール開封イメージ取得
     * @param {string} tobAccountId 
     * @param {string} [applyId] 応募ID
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public mailOpenImg(tobAccountId: string, applyId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return CommonApiFp(this.configuration).mailOpenImg(tobAccountId, applyId, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

