export enum RecoilAtomKeys {
  SIDEBAR_STATE = 'sidebarState',
  NOTICE_TOAST_STATE = 'noticeToastState',
  CURRENT_USER_STATE = 'currentUserState',
  REDIRECT_URL_AFTER_LOGIN = 'redirectUrlAfterLogin',
  SELECT_STACK_STATE = 'selectStackState',
  REGISTER_FORM_STATE = 'registerFormState',
  MAIN_SELECTED_AUTH_STATE = 'main_serected_auth_state',
  SUB_SELECTED_AUTH_STATE = 'sub_serected_auth_state',
  USER_LIST_STATE = 'userListState',
  UPDATE_USER_STATE = 'updateUserState',
  BELONG_DATA_LIST_STATE = 'belongDataListState',
  BELONG_RADIO_SELECTED_FLG_STATE = 'belongRadioSelectedFlgState',
  SEARCH_USER_FORM_STATE = 'searchUserFormState',
  MONITOR_PROGRESS_LIST_STATE = 'moniterProgressList',
  DIGITAL_TICKET_LIST_STATE = 'digitalTicketList',
  SHOP_STATE = 'shop',
  YM_STATE = 'ym',
  BILLING_YM_STATE = 'billingYm',
  QSC_CATEGORY_STATE = 'qscCategory',
  MONITOR_STATE = 'monitor',
  GENRE_STATE = 'genre',
  GIFT_STATE = 'gift',
  GIFT_STATUS_STATE = 'giftStatus',
  GOAL_PROGRESS_STATE = 'goalProgress',
  GOAL_BEFORE_DIFF_STATE = 'goalBeforeDiff',
  GOAL_AFTER_DIFF_STATE = 'goalAfterDiff',
  MOVING_AVERAGE_TYPE_STATE = 'movingAverageType',
  MOVING_AVERAGE_PERIOD_STATE = 'movingAveragePeriod',
  ACHIEVEMENT_RATE_FORMAT_TYPE_STATE = 'achievementRateFormatType',
  CONFIRM_MODAL_OPEN = 'confirm_open_modal',
  INQUIRY_MODAL_OPEN = 'inquiry_open_modal',
  ALL_CLIENT_STATE = 'all_client_state',
  STAFF_COMMENT_STATE = 'staff_comment_state',
  BUSSINESS_STATE = 'bussiness_state',
  AREA_STATE = 'area_state',
  PRICE_STATE = 'price_state',
  SALES_PERSONE_STATE = 'sales_person_state',
  INQUIRY_STATUS_STATE = 'inquiry_status_state',
  ENQUETE_STATE = 'enquete',
  MAIN_BELONG_LIST_STATE = 'main_belong_List_state',
  SEND_MAIL_STATE = 'send_mail_state',
  MAIN_SELECTED_BELONG_STATE = 'main_selected_belong_state',
  MAIN_BELONG_EDIT_FLG_STATE = 'main_belong_edit_flg_state',
  SUB_SELECTED_LIST_STATE = 'sub_selected_list_state',
  SUB_EDIT_FLG_STATE = 'sub_edit_flg_state',
  MAIN_SELECTED_ORG_LEVEL_STATE = 'main_selected_org_level_state',
  SUB_SELECTED_BELONG_STATE = 'sub_selected_belong_state',
  SUB_BELONG_LIST_STATE = 'sub_belong_list_state',
  OPEN_SUB_BELONG_MODAL_STATE = 'open_sub_belong_modal_state',
  EDIT_USER_INFO_STATE = 'edit_user_info_state',
  SCORE_VIEW_RANKING_LIST_STATE = 'score_view_ranking_list',
  CS_SCORE_RANK_STATE = 'cs_score_rank_state',
  OPEN_DOWNLOAD_MODAL_STATE = 'open_download_modal_state',
  DOWNLOAD_STATE = 'donwnload_state',
  EXCEL_DATA_STATE = 'excel_data_state',
  APPLY_STATE = 'applyState',
  SELECT_SHOP_LIST_STATE = 'firstShop',
  USER_NAME_STATE = 'useNameState',
  USER_MAIN_SELECT_RESET_STATE = 'userMainSelectResetFlg',
  USER_SUB_SELECT_RESET_STATE = 'userSubSelectResetFlg',
  MAIL_ADDLESS_STATE = 'mailAddlessState',
  USER_BELONG_STATE = 'userBelongState',
  BILLING_DESTINATION_STATE = 'billingDestinationState',
  ROI_ADMIN_SELECT_CLIENT_STATE = 'roiAdminSelectClientState',
}

export enum RecoilSelectorKeys {
  SEARCH_USER_SELECTOR = 'searchUserSelector',
  ORG_LEVEL_SELECTOR = 'org_level_selector',
  BELONG_SELECT_SELECTOR = 'belong_select_selector',
  SUB_BELONG_GROUP_SELECT_SELECTOR = 'sub_belong_group_select_selector',
  SUB_BELONG_HIERARCHY_RADIO_SELECTOR = 'sub_belong_hierarchy_radio_selector',
  SUB_BELONG_SHOP_SELECT_SELECTOR = 'sub_belong_shop_select_selector',
  EDIT_USER_INFO_SELECTOR = 'edit_user_info_selector',
}
