import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: ({ label }) => `${label}は必須入力項目です。`,
  },
  string: {
    email: 'メールアドレスの形式が間違っています。',
  },
});

export const schema = yup.object().shape({
  name: yup.string().required().label('名前'),
  mailaddress: yup.string().required().email().label('メールアドレス'),
  password: yup
    .string()
    .test('password-regexp', '使用できる文字は小文字、大文字、数字のみです。', (value) => {
      const regexp = /^[a-zA-Z0-9]/;
      if (value === undefined || value === '') {
        return true;
      }
      if (!regexp.test(value)) {
        return false;
      }
      return true;
    })
    .test('password-min-max', '4文字以上30文字以下で設定してください。', (value) => {
      if (value === undefined || value === '') {
        return true;
      }
      if (value.length < 4 || value.length > 30) {
        return false;
      }
      return true;
    }),
  confirmePassword: yup.string().test('password-match', 'パスワードが一致しません。', (value, context) => {
    const { password } = context.parent;
    if (password === undefined) {
      return true;
    }
    return value === password;
  }),
  belong: yup.string(),
  clientAdminFlg: yup.boolean(),
  giftViewFlg: yup.boolean(),
  goalSetFlg: yup.boolean(),
  initPassword: yup.boolean(),
  mailSendFlg: yup.boolean(),
  roiAdminFlg: yup.boolean(),
  userEditFlg: yup.boolean(),
  viewAllFlg: yup.boolean(),
});
