import React, { memo, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import scss from '../../scss/organisms/inquiry-modal.module.scss';
import { ResultOutputResponse } from '../../api-client';
import { openModal } from '../../states/atom/InquiryModalOpen';
import { openConfirmModal } from '../../states/atom/ConfirmModalOpen';
import { Button } from '../atoms/Button';
import { Url } from '../../constants/Url';

type Props = {
  title: string;
  content: string;
  url: string;
};
export const ConfirmModal: React.FC<Props> = ({ title, content, url }) => {
  const history = useHistory();
  const [open, setOpen] = useRecoilState(openConfirmModal);

  const handleHide = () => {
    setOpen(false);
    history.push(url);
  };

  return (
    <Modal show={open} onHide={handleHide} className={scss.modal}>
      <Modal.Header className={scss.modal_header} closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button className={`${scss.button} ${scss.action_slow} ${scss.btn_shadow}`} type="button" onClick={handleHide}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
