import ReactLoading from 'react-loading';
import React from 'react';
import { TailSpin } from 'react-loader-spinner';

export const Loading: React.FC = () => {
  const type = 'spinningBubbles';
  const color = 'rgba(54,178,186)';
  return <ReactLoading type={type} color={color} />;
};

export const SpinLoading: React.FC = () => {
  return (
    <TailSpin
      visible
      height="50"
      width="50"
      color="#36b2ba"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

export const DownloalLoading: React.FC = () => {
  const type = 'bars';
  const color = 'rgba(54,178,186)';
  return <ReactLoading type={type} color={color} />;
};
