/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CommentListOutputResponse } from '../domains';
// @ts-ignore
import { StaffCommentListOutputResponse } from '../domains';
/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * コメントExcelを取得する
         * @summary コメントExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentExcel: async (id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comment-list-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (averageType !== undefined) {
                localVarQueryParameter['averageType'] = averageType;
            }

            if (averagePeriod !== undefined) {
                localVarQueryParameter['averagePeriod'] = averagePeriod;
            }

            if (rateFormatType !== undefined) {
                localVarQueryParameter['rateFormatType'] = rateFormatType;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コメントを取得する
         * @summary コメント取得
         * @param {string} [commentListPage] ページID
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [cat] カテゴリID
         * @param {string} [catBaseType] カテゴリベースタイプID
         * @param {string} [applyIds] 応募ID一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentList: async (commentListPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, catBaseType?: string, applyIds?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/comment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (commentListPage !== undefined) {
                localVarQueryParameter['commentListPage'] = commentListPage;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (cat !== undefined) {
                localVarQueryParameter['cat'] = cat;
            }

            if (catBaseType !== undefined) {
                localVarQueryParameter['catBaseType'] = catBaseType;
            }

            if (applyIds !== undefined) {
                localVarQueryParameter['applyIds'] = applyIds;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 輝いているスタッフコメントを取得する
         * @summary 輝いているスタッフコメント取得
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [staffCommentId] スタッフコメントID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffCommentList: async (id?: string, ym?: string, monitorId?: string, staffCommentId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/staff-comment-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (staffCommentId !== undefined) {
                localVarQueryParameter['staffCommentId'] = staffCommentId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 輝いているスタッフコメントを取得するExcel
         * @summary 輝いているスタッフコメント取得
         * @param {string} id 年月
         * @param {string} ym 年月
         * @param {string} staffCommentId スタッフコメントID
         * @param {string} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffCommentListExcel: async (id: string, ym: string, staffCommentId: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('staffCommentListExcel', 'id', id)
            // verify required parameter 'ym' is not null or undefined
            assertParamExists('staffCommentListExcel', 'ym', ym)
            // verify required parameter 'staffCommentId' is not null or undefined
            assertParamExists('staffCommentListExcel', 'staffCommentId', staffCommentId)
            const localVarPath = `/api/staff-comment-list-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (staffCommentId !== undefined) {
                localVarQueryParameter['staffCommentId'] = staffCommentId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * コメントExcelを取得する
         * @summary コメントExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コメントを取得する
         * @summary コメント取得
         * @param {string} [commentListPage] ページID
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [cat] カテゴリID
         * @param {string} [catBaseType] カテゴリベースタイプID
         * @param {string} [applyIds] 応募ID一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commentList(commentListPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, catBaseType?: string, applyIds?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commentList(commentListPage, id, ym, monitorId, cat, catBaseType, applyIds, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 輝いているスタッフコメントを取得する
         * @summary 輝いているスタッフコメント取得
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [staffCommentId] スタッフコメントID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async staffCommentList(id?: string, ym?: string, monitorId?: string, staffCommentId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StaffCommentListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.staffCommentList(id, ym, monitorId, staffCommentId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 輝いているスタッフコメントを取得するExcel
         * @summary 輝いているスタッフコメント取得
         * @param {string} id 年月
         * @param {string} ym 年月
         * @param {string} staffCommentId スタッフコメントID
         * @param {string} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async staffCommentListExcel(id: string, ym: string, staffCommentId: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.staffCommentListExcel(id, ym, staffCommentId, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * コメントExcelを取得する
         * @summary コメントExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.commentExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * コメントを取得する
         * @summary コメント取得
         * @param {string} [commentListPage] ページID
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [cat] カテゴリID
         * @param {string} [catBaseType] カテゴリベースタイプID
         * @param {string} [applyIds] 応募ID一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentList(commentListPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, catBaseType?: string, applyIds?: string, allClient?: string, options?: any): AxiosPromise<CommentListOutputResponse> {
            return localVarFp.commentList(commentListPage, id, ym, monitorId, cat, catBaseType, applyIds, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 輝いているスタッフコメントを取得する
         * @summary 輝いているスタッフコメント取得
         * @param {string} [id] 年月
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [staffCommentId] スタッフコメントID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffCommentList(id?: string, ym?: string, monitorId?: string, staffCommentId?: string, allClient?: string, options?: any): AxiosPromise<StaffCommentListOutputResponse> {
            return localVarFp.staffCommentList(id, ym, monitorId, staffCommentId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 輝いているスタッフコメントを取得するExcel
         * @summary 輝いているスタッフコメント取得
         * @param {string} id 年月
         * @param {string} ym 年月
         * @param {string} staffCommentId スタッフコメントID
         * @param {string} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        staffCommentListExcel(id: string, ym: string, staffCommentId: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.staffCommentListExcel(id, ym, staffCommentId, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * コメントExcelを取得する
     * @summary コメントExcel
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [averageType] 
     * @param {string} [averagePeriod] 
     * @param {string} [rateFormatType] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public commentExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).commentExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コメントを取得する
     * @summary コメント取得
     * @param {string} [commentListPage] ページID
     * @param {string} [id] 年月
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターベースID
     * @param {string} [cat] カテゴリID
     * @param {string} [catBaseType] カテゴリベースタイプID
     * @param {string} [applyIds] 応募ID一覧
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public commentList(commentListPage?: string, id?: string, ym?: string, monitorId?: string, cat?: string, catBaseType?: string, applyIds?: string, allClient?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).commentList(commentListPage, id, ym, monitorId, cat, catBaseType, applyIds, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 輝いているスタッフコメントを取得する
     * @summary 輝いているスタッフコメント取得
     * @param {string} [id] 年月
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターベースID
     * @param {string} [staffCommentId] スタッフコメントID
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public staffCommentList(id?: string, ym?: string, monitorId?: string, staffCommentId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).staffCommentList(id, ym, monitorId, staffCommentId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 輝いているスタッフコメントを取得するExcel
     * @summary 輝いているスタッフコメント取得
     * @param {string} id 年月
     * @param {string} ym 年月
     * @param {string} staffCommentId スタッフコメントID
     * @param {string} [monitorId] モニターベースID
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public staffCommentListExcel(id: string, ym: string, staffCommentId: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).staffCommentListExcel(id, ym, staffCommentId, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

