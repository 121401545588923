import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title: string;
};

export const Head: React.FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>{`${title} | ファンくる`}</title>
      <meta httpEquiv="Content-Script-Type" content="text/javascript" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta
        httpEquiv="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
    </Helmet>
  );
};
