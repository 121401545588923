export const TITLE = {
  TOB: {
    LOGIN: 'ログイン',
    LOGOUT: 'ログアウト',
    INIT_PASSWORD: '初期パスワード変更',
    PASSWORD_FORGET: 'パスワードをお忘れになった場合',
    PASSWORD_RESET_MAIL_COMPLETE: 'パスワード再設定完了',
    PASSWORD_RESET: 'パスワード再設定',
    MY: 'マイページ',
    USER_LIST: 'ユーザ一覧',
    REGISTER_USER: 'ユーザ作成',
    ACCOUNT_EDIT: 'マイアカウント編集',
    EDIT_USER: 'ユーザ編集',
    SCORE_SHOP_BY_MONTH: '年間スコア推移をみる',
    COMMENT_LIST: '評価コメントをみる',
    STAFF_COMMENT_LIST: '輝いていたスタッフをみる',
    DIGITAL_TICKET_LIST: 'ギフト券の状況をみる',
    MONITOR_PROGRESS: 'モニターの進捗をみる',
    SHOP_CATEGORY_GOAL_RESULT: '改善実績をみる（本部）',
    SHOP_CATEGORY_GOAL_SETTING: '改善目標を設定する（本部）',
    SHOP_GOAL_RESULT: '改善実績をみる（店舗）',
    ANSEWR_DISTRIBUTION: 'スコアのばらつきをみる',
    CLIENT_IN_SHOP: '社内ランキングをみる',
    ENQUETE: 'アンケートをみる',
    IMAGE: '画像表示',
    IMAGE_VIEW: '撮影画像をみる',
    SCORE_VIEW: 'スコアをみる',
    NATIONAL_RANKING: '全国ランキングをみる',
    ENQUETE_CONTENT: 'アンケート設計内容をみる  ',
    CONFIRM_CONTRACT: '契約内容をみる',
    INVOICE_LIST: '請求書一覧をみる（Coming Soon）',
    INQUIRY_LIST: 'お問い合わせ一覧をみる',
    INQUIRY_DETAIL: 'お問い合わせ内容をみる',
    IMPROVE_QUESTION: '設問ごとの回答をみる',
    SHOP_GOAL_SETTING: '改善目標を設定する（店舗）',
    CLIENT_SELECT: 'クライアント選択',
    NEWS_LIST: 'お知らせ一覧',
    REGISTER_NEWS: 'お知らせ作成',
    EDIT_NEWS: 'お知らせ編集',
    LIBRARY: '動画をみる',
    CS_SCORE: 'CSスコアをみる',
    TOP_PAGE: 'Top画面',
  },
} as const;
