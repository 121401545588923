import React, { lazy, Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import './scss/custom.scss';
import { createBrowserHistory } from 'history';
import { RecoilRoot } from 'recoil';
import { Url } from './constants/Url';
import { GuestRoute } from './components/organisms/GuestRoute';
import { PrivateRoute } from './components/organisms/PrivateRoute';
import { PrePrivateRoute } from './components/organisms/PrePrivateRoute';
import { TITLE } from './constants/Title';
import InitPassPage from './components/pages/InitPassPage';
import MyEditUserPage from './components/pages/MyEditUserPage';
import { LoadingLayout } from './components/templates/LoadingLayout';
import ImagePage from './components/pages/ImagePage';
import { PrivateImageRoute } from './components/organisms/PrivateImageRoute';
import PassResetPage from './components/pages/PassResetPage';
import NoDataPage from './components/pages/NoDataPage';

const MyPage = lazy(() => import('./components/pages/MyPage'));
const MaintenancePage = lazy(() => import('./components/pages/MaintenancePage'));
const ErrorPage = lazy(() => import('./components/pages/ErrorPage'));
const AccessDenyPage = lazy(() => import('./components/pages/AccessDenyPage'));
const RegisterUserPage = lazy(() => import('./components/pages/RegisterUserPage'));
const UserListPage = lazy(() => import('./components/pages/UserListPage'));
const ScoreShopByMonthPage = lazy(() => import('./components/pages/ScoreShopByMonthPage'));
const CommentListPage = lazy(() => import('./components/pages/CommentListPage'));
const StaffCommentListPage = lazy(() => import('./components/pages/StaffCommentListPage'));
const EditUserPage = lazy(() => import('./components/pages/EditUserPage'));
const DigitalTicketListPage = lazy(() => import('./components/pages/DigitalTicketListPage'));
const ScoreViewPage = lazy(() => import('./components/pages/ScoreViewPage'));
const MonitorProgressPage = lazy(() => import('./components/pages/MonitorProgressPage'));
const ShopCategoryGoalResultPage = lazy(() => import('./components/pages/ShopCategoryGoalResultPage'));
const AnswerDistributionPage = lazy(() => import('./components/pages/AnswerDistributionPage'));
const ClientInShopRankingPage = lazy(() => import('./components/pages/ClientInShopRankingPage'));
const LoginPage = lazy(() => import('./components/pages/LoginPage'));
const PasswordForgetPage = lazy(() => import('./components/pages/PasswordForgetPage'));
const PasswordResetMailCompletePage = lazy(() => import('./components/pages/PasswordResetMailCompletePage'));
const ImageViewPage = lazy(() => import('./components/pages/ImageViewPage'));
const ShopGoalResultPage = lazy(() => import('./components/pages/ShopGoalResultPage'));
const EnquetePage = lazy(() => import('./components/pages/EnquetePage'));
const ConfirmContractPage = lazy(() => import('./components/pages/ConfirmContractPage'));
const NationalShopRankingPage = lazy(() => import('./components/pages/NationalShopRankingPage'));
const ClientSelectPage = lazy(() => import('./components/pages/ClientSelectPage'));
const InquiryDetailPage = lazy(() => import('./components/pages/InquiryDetailPage'));
const EnqueteDesignPage = lazy(() => import('./components/pages/EnqueteDesignPage'));
const InquiryListPage = lazy(() => import('./components/pages/InquiryListPage'));
const ImproveQuestionPage = lazy(() => import('./components/pages/ImproveQuestionPage'));
const LogoutPage = lazy(() => import('./components/pages/LogoutPage'));
const InvoiceListPage = lazy(() => import('./components/pages/InvoiceListPage'));
const NewsListPage = lazy(() => import('./components/pages/NewsListPage'));
const LibraryPage = lazy(() => import('./components/pages/LibraryPage'));
const ExistingScoreViewPage = lazy(() => import('./components/pages/ExistingScoreViewPage'));
const ShopCategoryGoalSettingPage = lazy(() => import('./components/pages/ShopCategoryGoalSettingPage'));
const ShopGoalSettingPage = lazy(() => import('./components/pages/ShopGoalSettingPage'));
const Error404 = lazy(() => import('./components/pages/Error404'));
const RegisterNewsPage = lazy(() => import('./components/pages/RegisterNewsPage'));

// ヒストリをRouterに持たせるためAppで定義している
export const history = createBrowserHistory();

// Appコンポーネント
const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Router history={history}>
        {/** React.lazy対応。Suspenseで囲む */}
        <Suspense fallback={<LoadingLayout />}>
          <Switch>
            {/* 未ログインのユーザのみがアクセスできる */}
            <GuestRoute path={Url.ROOT} title={TITLE.TOB.LOGIN} exact>
              <LoginPage />
            </GuestRoute>

            <GuestRoute path={Url.LOGIN} title={TITLE.TOB.LOGIN} exact={false}>
              <LoginPage />
            </GuestRoute>

            <GuestRoute path={Url.PASSWORD_FORGET} title={TITLE.TOB.PASSWORD_FORGET} exact={false}>
              <PasswordForgetPage />
            </GuestRoute>

            <GuestRoute path={Url.PASSWORD_RESET} title={TITLE.TOB.PASSWORD_RESET} exact={false}>
              <PassResetPage />
            </GuestRoute>

            <GuestRoute
              path={Url.PASSWORD_RESET_MAIL_COMPLETE}
              title={TITLE.TOB.PASSWORD_RESET_MAIL_COMPLETE}
              exact={false}
            >
              <PasswordResetMailCompletePage />
            </GuestRoute>

            {/* ログイン済みのユーザのみがアクセスできる */}
            {/* <PrePrivateRoute path={Url.CLIENT_SELECT} title={TITLE.TOB.CLIENT_SELECT}> */}
            <PrivateRoute path={Url.CLIENT_SELECT} title={TITLE.TOB.CLIENT_SELECT}>
              <ClientSelectPage />
            </PrivateRoute>
            {/* </PrePrivateRoute> */}

            <PrivateRoute path={Url.LOGOUT} title={TITLE.TOB.LOGOUT}>
              <LogoutPage />
            </PrivateRoute>

            <PrivateRoute path={Url.INIT_PASSWORD} title={TITLE.TOB.INIT_PASSWORD}>
              <InitPassPage />
            </PrivateRoute>

            <PrivateRoute path={Url.USER_LIST} title={TITLE.TOB.USER_LIST}>
              <UserListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.REGISTER_USER} title={TITLE.TOB.REGISTER_USER}>
              <RegisterUserPage />
            </PrivateRoute>

            <PrivateRoute path={Url.EDIT_ACCOUNT} title={TITLE.TOB.ACCOUNT_EDIT}>
              <MyEditUserPage />
            </PrivateRoute>

            <PrivateRoute path={Url.EDIT_USER} title={TITLE.TOB.EDIT_USER}>
              <EditUserPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SCORE_VIEW} title={TITLE.TOB.SCORE_VIEW}>
              <ScoreViewPage />
            </PrivateRoute>

            <PrivateRoute path={Url.ANSWER_DISTRIBUTION} title={TITLE.TOB.ANSEWR_DISTRIBUTION}>
              <AnswerDistributionPage />
            </PrivateRoute>

            <PrivateRoute path={Url.CLIENT_IN_SHOP_RANKING} title={TITLE.TOB.CLIENT_IN_SHOP}>
              <ClientInShopRankingPage />
            </PrivateRoute>

            <PrivateRoute path={Url.IMAGE_VIEW} title={TITLE.TOB.IMAGE_VIEW}>
              <ImageViewPage />
            </PrivateRoute>

            <PrivateRoute path={Url.ENQUETE} title={TITLE.TOB.ENQUETE}>
              <EnquetePage />
            </PrivateRoute>

            <PrivateImageRoute path={Url.IMAGE} title={TITLE.TOB.IMAGE}>
              <ImagePage />
            </PrivateImageRoute>

            <PrivateRoute path={Url.ENQUETE_DESIGN} title={TITLE.TOB.ENQUETE_CONTENT}>
              <EnqueteDesignPage />
            </PrivateRoute>

            <PrivateRoute path={Url.CONFIRM_CONTRACT} title={TITLE.TOB.CONFIRM_CONTRACT}>
              <ConfirmContractPage />
            </PrivateRoute>

            <PrivateRoute path={Url.NATIONAL_SHOP_RANKING} title={TITLE.TOB.NATIONAL_RANKING}>
              <NationalShopRankingPage />
            </PrivateRoute>

            <PrivateRoute path={Url.INQUIRY_LIST} title={TITLE.TOB.INQUIRY_LIST}>
              <InquiryListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.INVOICE_LIST} title={TITLE.TOB.INVOICE_LIST}>
              <InvoiceListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.INQUIRY_DETAIL} title={TITLE.TOB.INQUIRY_DETAIL}>
              <InquiryDetailPage />
            </PrivateRoute>

            <PrivateRoute path={Url.MY_PAGE} title={TITLE.TOB.MY}>
              <MyPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SCORE_SHOP_BY_MONTH} title={TITLE.TOB.SCORE_SHOP_BY_MONTH}>
              <ScoreShopByMonthPage />
            </PrivateRoute>

            <PrivateRoute path={Url.COMMENT_LIST} title={TITLE.TOB.COMMENT_LIST}>
              <CommentListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.STAFF_COMMENT_LIST} title={TITLE.TOB.STAFF_COMMENT_LIST}>
              <StaffCommentListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.DIGITAL_TICKET_LIST} title={TITLE.TOB.DIGITAL_TICKET_LIST}>
              <DigitalTicketListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.MONITOR_PROGRESS} title={TITLE.TOB.MONITOR_PROGRESS}>
              <MonitorProgressPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SHOP_CATEGORY_GOAL_RESULT} title={TITLE.TOB.SHOP_CATEGORY_GOAL_RESULT}>
              <ShopCategoryGoalResultPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SHOP_CATEGORY_GOAL_SETTING} title={TITLE.TOB.SHOP_CATEGORY_GOAL_SETTING}>
              <ShopCategoryGoalSettingPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SHOP_GOAL_RESULT} title={TITLE.TOB.SHOP_GOAL_RESULT}>
              <ShopGoalResultPage />
            </PrivateRoute>

            <PrivateRoute path={Url.IMPROVE_QUESTION} title={TITLE.TOB.IMPROVE_QUESTION}>
              <ImproveQuestionPage />
            </PrivateRoute>

            <PrivateRoute path={Url.SHOP_GOAL_SETTING} title={TITLE.TOB.SHOP_GOAL_SETTING}>
              <ShopGoalSettingPage />
            </PrivateRoute>

            <PrivateRoute path={Url.NEWS_LIST} title={TITLE.TOB.NEWS_LIST}>
              <NewsListPage />
            </PrivateRoute>

            <PrivateRoute path={Url.REGISTER_NEWS} title={TITLE.TOB.REGISTER_NEWS}>
              <RegisterNewsPage />
            </PrivateRoute>

            <PrivateRoute path={Url.EDIT_NEWS} title={TITLE.TOB.EDIT_NEWS}>
              <RegisterNewsPage />
            </PrivateRoute>

            <PrivateRoute path={Url.LIBRARY} title={TITLE.TOB.LIBRARY}>
              <LibraryPage />
            </PrivateRoute>

            <PrivateRoute path={Url.CS_SCORE} title={TITLE.TOB.CS_SCORE}>
              <ExistingScoreViewPage />
            </PrivateRoute>

            {/* エラーページ */}
            <Route path={Url.COMMON_ERROR}>
              <ErrorPage />
            </Route>
            {/* NodDataラーページ */}
            <Route path={Url.NO_DATA}>
              <NoDataPage />
            </Route>
            {/* アクセス拒否 */}
            <Route path={Url.ACCESS_DENY}>
              <AccessDenyPage />
            </Route>
            <Route path="*">
              <Error404 />
            </Route>

            <Route path={Url.MAINTENANCE}>
              <MaintenancePage />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </RecoilRoot>
  );
};

export default App;
