import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import {
  AccountApi,
  TobAccountInfoOutputResponse,
  TobAccountSubListOutputResponse,
  TobAccountSubOrganizationRowDataResponse,
} from '../api-client';
import { useCurrentUser } from './useCurrentUser';
import { useParamClientAll } from './useParamClientAll';
import { useUrlParams } from './useUrlParams';

export interface MainBelongFlgParam {
  defaultBelongCode: string;
  data: TobAccountSubListOutputResponse;
}

interface MainBelongFlgContextInterface {
  mainEditFlg: boolean;
  setMainEditFlg: Dispatch<SetStateAction<boolean>>;
}
export interface Props {
  children: ReactNode;
}

// コンテキストを作成する
export const MainBelongFlgContext = createContext<MainBelongFlgContextInterface>({
  mainEditFlg: false,
  setMainEditFlg: () => undefined,
});

export const MainBelongFlgParamProvider: React.FC<Props> = ({ children }) => {
  const [mainEditFlg, setMainEditFlg] = useState(false);

  return (
    <MainBelongFlgContext.Provider value={{ mainEditFlg, setMainEditFlg }}>{children}</MainBelongFlgContext.Provider>
  );
};
