import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN, LOGOUT_DELETE_COOKIE, REFRESH_TOKEN, SELECT_STACK } from '../Constants';
import { TokenObj } from '../interfaces/TokenObj';
import {
  AuthUserOutputResponse,
  ClientSelectFormResponse,
  LoginApi,
  LoginEndpointApi,
  ApiLoginPostRequestResponse,
} from '../api-client';
import { CurrentUser } from '../interfaces/user';

// ログイン処理
export const login = async (loginData: ApiLoginPostRequestResponse) => {
  const loginApi = new LoginEndpointApi();
  return loginApi
    .apiLoginPost(loginData)
    .then((response: AxiosResponse<string>) => {
      // const tokenObj: TokenObj = {
      //   access_token: response.headers.authorization,
      //   refresh_token: response.headers.token,
      // };
      // LocalStrageService.setToken(tokenObj);
      Cookies.set(ACCESS_TOKEN, response.headers.accesstoken, { expires: 30 });
      Cookies.set(REFRESH_TOKEN, response.headers.refreshtoken, { expires: 30 });
      return true;
    })
    .catch((error) => {
      // handle error
      console.log(error);
      return false;
    });
};

/**
 * ログイン済みのユーザ情報を取得する
 */
export const getUser = async () => {
  const api = new LoginApi();
  return api
    .user()
    .then((response: AxiosResponse<AuthUserOutputResponse>) => {
      const user: CurrentUser = response.data;
      if (!user.auth) {
        return false;
      }
      return user;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

// ログイン処理
export const loginUpdate = async (data: ClientSelectFormResponse) => {
  const api = new LoginApi();
  return api
    .clientLoginUpdate(data)
    .then((response: AxiosResponse<AuthUserOutputResponse>) => {
      Cookies.set(ACCESS_TOKEN, response.headers.accesstoken, { expires: 30 });
      Cookies.set(REFRESH_TOKEN, response.headers.refreshtoken, { expires: 30 });
      return true;
    })
    .catch((error) => {
      return false;
    });
};

// 管理者成り代わりログイン処理
export const inAdminLoginUpdate = async (allClientId: string) => {
  const api = new LoginApi();
  return api
    .inAdminClientLoginUpdate(allClientId)
    .then((response: AxiosResponse<AuthUserOutputResponse>) => {
      Cookies.set(ACCESS_TOKEN, response.headers.accesstoken, { expires: 30 });
      Cookies.set(REFRESH_TOKEN, response.headers.refreshtoken, { expires: 30 });
      return true;
    })
    .catch((error) => {
      console.log(error);
      return error.data;
    });
};

/**
 * ログアウト
 */
export const logout: () => void = () => {
  // localStorage.removeItem(ACCESS_TOKEN);
  // localStorage.removeItem(REFRESH_TOKEN);
  LOGOUT_DELETE_COOKIE.forEach((s) => {
    Cookies.remove(s);
  });
};

// 現在のユーザ
export const getCurrentJwt: () => TokenObj | boolean = () => {
  const token = Cookies.get(ACCESS_TOKEN);
  const refreshToken = Cookies.get(REFRESH_TOKEN);
  if (token != null && refreshToken != null) {
    const tokenObj: TokenObj = {
      access_token: token,
      refresh_token: refreshToken,
    };
    return tokenObj;
  }
  return false;
};
