import React, { JSXElementConstructor } from 'react';
import { Container } from 'react-bootstrap';
import scss from '../../scss/templates/loginLayout.module.scss';
import { Head } from '../organisms/Head';

type Props = {
  title: string;
};

export const LoginLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <Head title={title} />
      <main>
        <Container fluid>{children}</Container>
        <footer className={`${scss.login_footer} fixed-bottom`}>
          <div>
            <p className={scss.copyrights}>©Fancrew Inc.</p>
          </div>
        </footer>
      </main>
    </>
  );
};
