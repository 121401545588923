import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Title } from '../atoms/Title';
import scss from '../../scss/templates/mainLayout.module.scss';

type Props = {
  title: string;
};

const ErrorAuthPage: React.VFC<Props> = ({ title }) => {
  return (
    <>
      <Container fluid className={`${scss.form_group}`}>
        <Row>
          <Col md="auto">
            <Title>{title}</Title>
          </Col>
        </Row>
      </Container>
      <Container fluid className={`${scss.result_group} mb-5`}>
        権限が無いため、画面を表示できません。
      </Container>
    </>
  );
};

export default ErrorAuthPage;
