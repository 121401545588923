import React from 'react';
import { DeepMap, DeepPartial, FieldError, UseFormRegister } from 'react-hook-form';
import scss from '../../../scss/organisms/registerUser.module.scss';
import { TobAccountCreateFormResponse } from '../../../api-client';
import { ToolTip } from '../../atoms/Tooltip';

type TextFormProps = {
  register: UseFormRegister<TobAccountCreateFormResponse>;
  errors: DeepMap<DeepPartial<TobAccountCreateFormResponse>, FieldError>;
  error: { [key: string]: string } | undefined;
  submitting: boolean;
};

export const TextForm: React.FC<TextFormProps> = ({ register, errors, error, submitting }) => {
  return (
    <>
      <label htmlFor="name" className="fw-bold mt-2 d-block">
        ユーザ名
        <ToolTip content="ユーザの名前になります。">
          <input type="text" {...register('name')} className="w-100 form-control" id="name" disabled={submitting} />
        </ToolTip>
      </label>
      <p style={{ color: '#EB5757' }}>
        {errors.name?.message}
        {error?.name}
      </p>
      <label htmlFor="mailaddress" className="fw-bold mt-2 d-block">
        メールアドレス
        <ToolTip content="ログインIDやメールの受信先として使用されます。">
          <input
            type="text"
            {...register('mailaddress')}
            className="w-100 form-control"
            autoComplete="off"
            disabled={submitting}
          />
        </ToolTip>
      </label>
      <p style={{ color: '#EB5757' }}>
        {errors.mailaddress?.message}
        {error?.mailaddress}
      </p>
    </>
  );
};

export const PasswordForm: React.FC<TextFormProps> = ({ register, errors, error, submitting }) => {
  return (
    <>
      <label htmlFor="pass" className={scss.label} style={{ display: 'block' }}>
        パスワード
        <ToolTip content="ログイン時のパスワードになります。">
          <input
            type="password"
            className="form-control"
            {...register('password')}
            style={{ width: '100%' }}
            autoComplete="off"
            id="pass"
          />
        </ToolTip>
      </label>
      <p style={{ color: '#EB5757' }}>
        {errors.password?.message}
        {error?.password}
      </p>
      <label htmlFor="confirm" className={scss.label} style={{ display: 'block' }}>
        確認パスワード
        <ToolTip content="上記パスワードと同様のパスワードを入力してください。">
          <input
            type="password"
            {...register('confirmePassword')}
            className="form-control"
            style={{ width: '100%' }}
            autoComplete="off"
            id="confirm"
          />
        </ToolTip>
      </label>
      <p style={{ color: '#EB5757' }}>
        {errors.confirmePassword?.message}
        {error?.confirmePassword}
      </p>
    </>
  );
};
