import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { mainSelectedAuthState, sendMailState } from '../../../states/atom/RegisterFormState';
import { ToolTip } from '../../atoms/Tooltip';
import { useUrlParams } from '../../../hooks/useUrlParams';

export const MailSelectGroup: React.FC = memo(() => {
  const { pathname } = useLocation();
  const params = useUrlParams();
  const numAccountId = params.accountUpdateId === '' ? '' : params.accountUpdateId;
  const mailFlg = useRecoilValue(sendMailState(numAccountId));

  return (
    <Form.Group className="mb-3 w-100" controlId="sendMail">
      <Form.Label className="fw-bold mt-2">速報メール受信条件</Form.Label>
      {mailFlg ? (
        <ToolTip content="メール受信設定をしている場合のみ、アンケートの提出完了分のメール受信条件を設定できます。">
          <Form.Control as="select" className="mb-3 w-100">
            {[
              '全てのメール受信する',
              '90点以上のメール受信する',
              '75点以上のメール受信する',
              '90点以上と75点以下のメール受信する',
            ].map((v) => (
              <option value={v} key={v}>
                {v}
              </option>
            ))}
          </Form.Control>
        </ToolTip>
      ) : (
        <ToolTip content="メール受信設定をしている場合のみ、アンケートの提出完了分のメール受信条件を設定できます。">
          <Form.Control as="select" className="mb-3 w-100" disabled>
            <option value="全てのメールを受信する">全てのメールを受信する</option>
          </Form.Control>
        </ToolTip>
      )}
    </Form.Group>
  );
});
