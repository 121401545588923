const SUCCESS = 'success';
const ERROR = 'error';
const PENDING = 'pending';

export const SuspendPromise = (promise: Promise<any>) => {
  let status = PENDING;
  let error: any;
  let result: any;
  const suspender = promise.then(
    (r) => {
      status = SUCCESS;
      result = r.data;
    },
    (e) => {
      status = ERROR;
      error = e;
    }
  );
  return {
    read() {
      if (status === PENDING) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw suspender;
      } else if (status === ERROR) {
        throw error;
      } else if (status === SUCCESS) {
        return result;
      }
    },
  };
};
