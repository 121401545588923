export const Common = {
  mediaQuery: {
    pc: {
      minWidth: 992,
      breakpoints: '@media only screen and (min-width: 992px)',
    },
    tab: {
      minWidth: 768,
      maxWidth: 991,
      breakpoints: '@media only screen and (min-width: 768px) and (max-width: 991px)',
    },
    sp: {
      maxWidth: 767,
      breakpoints: '@media only screen and (max-width: 767px)',
    },
  },
  // 所属
  beLong: {
    // ユーザ登録・編集ページのラジオボタン
    mainAuth: {
      // Client
      clientMainAuth: '1',
      // shop
      shopMainAuth: '3',
    },
  },
} as const;
