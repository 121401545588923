import { useLocation } from 'react-router-dom';

type UrlParamValue = {
  id: string;
  ym: string;
  monitor: string;
  enquete: string;
  cat: string;
  averageType: string;
  period: string;
  formatType: string;
  path: string;
  width: string;
  height: string;
  applyIds: string;
  staffCommentId: string;
  accountUpdateId: string;
  accountUpdateClientOrgShopId: string;
  allClient: string;
  billingYm: string;
  billingDestinationId: string;
  token: string;
};

export const useUrlParams = (): UrlParamValue => {
  const { search } = useLocation();
  let params: UrlParamValue = {
    id: '',
    ym: '',
    monitor: '',
    enquete: '',
    cat: '',
    averageType: '',
    period: '',
    path: '',
    width: '',
    height: '',
    formatType: '',
    applyIds: '',
    staffCommentId: '',
    accountUpdateId: '',
    accountUpdateClientOrgShopId: '',
    allClient: '',
    billingYm: '',
    billingDestinationId: '',
    token: '',
  };
  // URLパラメータを文字列で取得(?含む)
  let urlParamStr = search;
  if (urlParamStr) {
    // ?を除去
    urlParamStr = urlParamStr.substring(1);
    // urlパラメータをオブジェクトにまとめる
    urlParamStr.split('&').forEach((param) => {
      const temp = param.split('=');
      // pramsオブジェクトにパラメータを追加
      params = {
        ...params,
        [temp[0]]: temp[1],
      };
    });
  }
  return params;
};

// TODO カスタムフックで登録したいが、エラーになる。
// export const useSetUrlParams = (key: string, value: string) => {
//   const history = useHistory();
//   const { pathname, search } = useLocation();
//   history.push({
//     pathname: pathname,
//     search: urlParamConv(search, key, value),
//   });
// };
//
// /**
//  * クエリパラメータを作成する
//  *
//  * @param search
//  * @param key
//  * @param value
//  */
// const urlParamConv = (search: string, key: string, value: string) => {
//   let result = '';
//   // URLパラメータを文字列で取得(?含む)
//   let urlParamStr = search;
//   if (urlParamStr) {
//     // ?を除去
//     urlParamStr = urlParamStr.substring(1);
//     // urlパラメータをオブジェクトにまとめる
//     urlParamStr.split('&').forEach((param) => {
//       const temp = param.split('=');
//       if (temp[0] === key && value === '') return; // continue
//       // pramsオブジェクトにパラメータを追加
//       if (result === '') {
//         result = temp[0] === key ? `?${key}=${value}` : `?${param}`;
//       } else {
//         result += temp[0] === key ? `&${key}=${value}` : `&${param}`;
//       }
//     });
//     if (result.indexOf(key) === -1 && value !== '') {
//       result += `&${key}=${value}`;
//     }
//   } else if (value !== '') {
//     result = `?${key}=${value}`;
//   }
//   return result;
// };
