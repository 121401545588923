/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AllRankingScoreViewOutputResponse } from '../domains';
// @ts-ignore
import { ClientRankingScoreViewOutputResponse } from '../domains';
// @ts-ignore
import { CloudAllRankingOutputResponse } from '../domains';
// @ts-ignore
import { CloudClientInRankingOutputResponse } from '../domains';
// @ts-ignore
import { ShopRankingOutputResponse } from '../domains';
/**
 * RankingApi - axios parameter creator
 * @export
 */
export const RankingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 選択全国クライアント/店舗ランク取得する
         * @summary 選択全国クライアント/店舗ランク取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [genreId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRankingScoreView: async (id?: string, ym?: string, genreId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/all-ranking-score-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (genreId !== undefined) {
                localVarQueryParameter['genreId'] = genreId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント/組織内店舗ランキングを取得する
         * @summary 店舗ランキング取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 社内管理者向けID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientInShopRankingList: async (id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-ranking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (baseCat !== undefined) {
                localVarQueryParameter['baseCat'] = baseCat;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント/組織内店舗ランキングExcelを取得する
         * @summary 店舗ランキング取得Excel
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientInShopRankingListExcel: async (id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-ranking-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (baseCat !== undefined) {
                localVarQueryParameter['baseCat'] = baseCat;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 社内ランキング（スコアをみる）を取得する
         * @summary 社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRankingScoreView: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-ranking-score-view`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラウドCS社内ランキング（スコアをみる）取得する
         * @summary クラウドCS社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudClientInRanking: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cloud-client-in-ranking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラウドCS全国ランキング（スコアをみる）取得する
         * @summary クラウドCS全国ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreViewAllRanking: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cloud-score-view-all-ranking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 全社店舗ランキングを取得する
         * @summary 全社店舗ランキング取得
         * @param {string} [ym] 年月
         * @param {string} [genreId] 業態
         * @param {string} [area] エリア
         * @param {number} [priceMin] 価格帯Min
         * @param {number} [priceMax] 価格帯Max
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopRankingForAllList: async (ym?: string, genreId?: string, area?: string, priceMin?: number, priceMax?: number, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-ranking-for-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (genreId !== undefined) {
                localVarQueryParameter['genreId'] = genreId;
            }

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (priceMin !== undefined) {
                localVarQueryParameter['priceMin'] = priceMin;
            }

            if (priceMax !== undefined) {
                localVarQueryParameter['priceMax'] = priceMax;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RankingApi - functional programming interface
 * @export
 */
export const RankingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RankingApiAxiosParamCreator(configuration)
    return {
        /**
         * 選択全国クライアント/店舗ランク取得する
         * @summary 選択全国クライアント/店舗ランク取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [genreId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allRankingScoreView(id?: string, ym?: string, genreId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllRankingScoreViewOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allRankingScoreView(id, ym, genreId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント/組織内店舗ランキングを取得する
         * @summary 店舗ランキング取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 社内管理者向けID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientInShopRankingList(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopRankingOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientInShopRankingList(id, ym, monitorId, baseCat, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント/組織内店舗ランキングExcelを取得する
         * @summary 店舗ランキング取得Excel
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientInShopRankingListExcel(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientInShopRankingListExcel(id, ym, monitorId, baseCat, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 社内ランキング（スコアをみる）を取得する
         * @summary 社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientRankingScoreView(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientRankingScoreViewOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientRankingScoreView(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラウドCS社内ランキング（スコアをみる）取得する
         * @summary クラウドCS社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudClientInRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudClientInRankingOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudClientInRanking(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラウドCS全国ランキング（スコアをみる）取得する
         * @summary クラウドCS全国ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudScoreViewAllRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudAllRankingOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudScoreViewAllRanking(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 全社店舗ランキングを取得する
         * @summary 全社店舗ランキング取得
         * @param {string} [ym] 年月
         * @param {string} [genreId] 業態
         * @param {string} [area] エリア
         * @param {number} [priceMin] 価格帯Min
         * @param {number} [priceMax] 価格帯Max
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopRankingForAllList(ym?: string, genreId?: string, area?: string, priceMin?: number, priceMax?: number, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopRankingOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopRankingForAllList(ym, genreId, area, priceMin, priceMax, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RankingApi - factory interface
 * @export
 */
export const RankingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RankingApiFp(configuration)
    return {
        /**
         * 選択全国クライアント/店舗ランク取得する
         * @summary 選択全国クライアント/店舗ランク取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [genreId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allRankingScoreView(id?: string, ym?: string, genreId?: string, allClient?: string, options?: any): AxiosPromise<AllRankingScoreViewOutputResponse> {
            return localVarFp.allRankingScoreView(id, ym, genreId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント/組織内店舗ランキングを取得する
         * @summary 店舗ランキング取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 社内管理者向けID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientInShopRankingList(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: any): AxiosPromise<ShopRankingOutputResponse> {
            return localVarFp.clientInShopRankingList(id, ym, monitorId, baseCat, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント/組織内店舗ランキングExcelを取得する
         * @summary 店舗ランキング取得Excel
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターID
         * @param {string} [baseCat] カテゴリ（グルメ、美容）
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientInShopRankingListExcel(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.clientInShopRankingListExcel(id, ym, monitorId, baseCat, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 社内ランキング（スコアをみる）を取得する
         * @summary 社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientRankingScoreView(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<ClientRankingScoreViewOutputResponse> {
            return localVarFp.clientRankingScoreView(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クラウドCS社内ランキング（スコアをみる）取得する
         * @summary クラウドCS社内ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudClientInRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<CloudClientInRankingOutputResponse> {
            return localVarFp.cloudClientInRanking(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クラウドCS全国ランキング（スコアをみる）取得する
         * @summary クラウドCS全国ランキング（スコアをみる）取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreViewAllRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<CloudAllRankingOutputResponse> {
            return localVarFp.cloudScoreViewAllRanking(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 全社店舗ランキングを取得する
         * @summary 全社店舗ランキング取得
         * @param {string} [ym] 年月
         * @param {string} [genreId] 業態
         * @param {string} [area] エリア
         * @param {number} [priceMin] 価格帯Min
         * @param {number} [priceMax] 価格帯Max
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopRankingForAllList(ym?: string, genreId?: string, area?: string, priceMin?: number, priceMax?: number, allClient?: string, options?: any): AxiosPromise<ShopRankingOutputResponse> {
            return localVarFp.shopRankingForAllList(ym, genreId, area, priceMin, priceMax, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RankingApi - object-oriented interface
 * @export
 * @class RankingApi
 * @extends {BaseAPI}
 */
export class RankingApi extends BaseAPI {
    /**
     * 選択全国クライアント/店舗ランク取得する
     * @summary 選択全国クライアント/店舗ランク取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [genreId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public allRankingScoreView(id?: string, ym?: string, genreId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).allRankingScoreView(id, ym, genreId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント/組織内店舗ランキングを取得する
     * @summary 店舗ランキング取得
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターID
     * @param {string} [baseCat] カテゴリ（グルメ、美容）
     * @param {string} [allClient] 社内管理者向けID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public clientInShopRankingList(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).clientInShopRankingList(id, ym, monitorId, baseCat, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント/組織内店舗ランキングExcelを取得する
     * @summary 店舗ランキング取得Excel
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターID
     * @param {string} [baseCat] カテゴリ（グルメ、美容）
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public clientInShopRankingListExcel(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).clientInShopRankingListExcel(id, ym, monitorId, baseCat, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 社内ランキング（スコアをみる）を取得する
     * @summary 社内ランキング（スコアをみる）取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public clientRankingScoreView(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).clientRankingScoreView(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラウドCS社内ランキング（スコアをみる）取得する
     * @summary クラウドCS社内ランキング（スコアをみる）取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public cloudClientInRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).cloudClientInRanking(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラウドCS全国ランキング（スコアをみる）取得する
     * @summary クラウドCS全国ランキング（スコアをみる）取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public cloudScoreViewAllRanking(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).cloudScoreViewAllRanking(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 全社店舗ランキングを取得する
     * @summary 全社店舗ランキング取得
     * @param {string} [ym] 年月
     * @param {string} [genreId] 業態
     * @param {string} [area] エリア
     * @param {number} [priceMin] 価格帯Min
     * @param {number} [priceMax] 価格帯Max
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RankingApi
     */
    public shopRankingForAllList(ym?: string, genreId?: string, area?: string, priceMin?: number, priceMax?: number, allClient?: string, options?: AxiosRequestConfig) {
        return RankingApiFp(this.configuration).shopRankingForAllList(ym, genreId, area, priceMin, priceMax, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

