import Cookies from 'js-cookie';
import { SELECT_STACK } from '../Constants';

export const useSelectStack = () => {
  const selectStack = Cookies.get(SELECT_STACK);
  // const selectStack = useRecoilValue(selectStackState);
  let selectMode = false;
  let stackMode = false;
  if (selectStack) {
    selectMode = selectStack === '2'; // 選択式
    stackMode = selectStack === '3'; // 積上式
  }
  return {
    selectMode,
    stackMode,
  };
};
