/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InquiryCategoryOutputResponse } from '../domains';
// @ts-ignore
import { InquiryInfoOutputResponse } from '../domains';
// @ts-ignore
import { InquiryListOutputResponse } from '../domains';
// @ts-ignore
import { InquryUpdateFormResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
/**
 * InquieryApi - axios parameter creator
 * @export
 */
export const InquieryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 問合せ区分取得する
         * @summary 問合せ区分取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquieryCategory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inquiry-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 問合せ情報取得する
         * @summary 問合せ情報取得
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryInfo: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inquiry-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 問合せ一覧取得する
         * @summary 問合せ一覧取得
         * @param {number} [accountId] 営業担当者ID
         * @param {number} [clientId] クライアントID
         * @param {number} [status] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryList: async (accountId?: number, clientId?: number, status?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/inquiry-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 問合せ登録する
         * @summary 問合せ登録
         * @param {InquryUpdateFormResponse} inquryUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquryCreate: async (inquryUpdateFormResponse: InquryUpdateFormResponse, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inquryUpdateFormResponse' is not null or undefined
            assertParamExists('inquryCreate', 'inquryUpdateFormResponse', inquryUpdateFormResponse)
            const localVarPath = `/api/inquiry-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inquryUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InquieryApi - functional programming interface
 * @export
 */
export const InquieryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InquieryApiAxiosParamCreator(configuration)
    return {
        /**
         * 問合せ区分取得する
         * @summary 問合せ区分取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquieryCategory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquiryCategoryOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquieryCategory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 問合せ情報取得する
         * @summary 問合せ情報取得
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiryInfo(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InquiryInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 問合せ一覧取得する
         * @summary 問合せ一覧取得
         * @param {number} [accountId] 営業担当者ID
         * @param {number} [clientId] クライアントID
         * @param {number} [status] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquiryList(accountId?: number, clientId?: number, status?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InquiryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquiryList(accountId, clientId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 問合せ登録する
         * @summary 問合せ登録
         * @param {InquryUpdateFormResponse} inquryUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inquryCreate(inquryUpdateFormResponse: InquryUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inquryCreate(inquryUpdateFormResponse, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InquieryApi - factory interface
 * @export
 */
export const InquieryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InquieryApiFp(configuration)
    return {
        /**
         * 問合せ区分取得する
         * @summary 問合せ区分取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquieryCategory(options?: any): AxiosPromise<Array<InquiryCategoryOutputResponse>> {
            return localVarFp.inquieryCategory(options).then((request) => request(axios, basePath));
        },
        /**
         * 問合せ情報取得する
         * @summary 問合せ情報取得
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryInfo(id?: number, options?: any): AxiosPromise<InquiryInfoOutputResponse> {
            return localVarFp.inquiryInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 問合せ一覧取得する
         * @summary 問合せ一覧取得
         * @param {number} [accountId] 営業担当者ID
         * @param {number} [clientId] クライアントID
         * @param {number} [status] ステータス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquiryList(accountId?: number, clientId?: number, status?: number, options?: any): AxiosPromise<Array<InquiryListOutputResponse>> {
            return localVarFp.inquiryList(accountId, clientId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 問合せ登録する
         * @summary 問合せ登録
         * @param {InquryUpdateFormResponse} inquryUpdateFormResponse 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inquryCreate(inquryUpdateFormResponse: InquryUpdateFormResponse, allClient?: string, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.inquryCreate(inquryUpdateFormResponse, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InquieryApi - object-oriented interface
 * @export
 * @class InquieryApi
 * @extends {BaseAPI}
 */
export class InquieryApi extends BaseAPI {
    /**
     * 問合せ区分取得する
     * @summary 問合せ区分取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquieryApi
     */
    public inquieryCategory(options?: AxiosRequestConfig) {
        return InquieryApiFp(this.configuration).inquieryCategory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問合せ情報取得する
     * @summary 問合せ情報取得
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquieryApi
     */
    public inquiryInfo(id?: number, options?: AxiosRequestConfig) {
        return InquieryApiFp(this.configuration).inquiryInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問合せ一覧取得する
     * @summary 問合せ一覧取得
     * @param {number} [accountId] 営業担当者ID
     * @param {number} [clientId] クライアントID
     * @param {number} [status] ステータス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquieryApi
     */
    public inquiryList(accountId?: number, clientId?: number, status?: number, options?: AxiosRequestConfig) {
        return InquieryApiFp(this.configuration).inquiryList(accountId, clientId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 問合せ登録する
     * @summary 問合せ登録
     * @param {InquryUpdateFormResponse} inquryUpdateFormResponse 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InquieryApi
     */
    public inquryCreate(inquryUpdateFormResponse: InquryUpdateFormResponse, allClient?: string, options?: AxiosRequestConfig) {
        return InquieryApiFp(this.configuration).inquryCreate(inquryUpdateFormResponse, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

