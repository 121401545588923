import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import scss from '../../../scss/organisms/registerUser.module.scss';

type Props = {
  text: string;
  onClick: () => void;
  styleClass?: string;
};

export const UnitButton: React.FC<Props> = ({ text, onClick, styleClass }) => {
  return (
    <Button onClick={onClick} className={`${scss.unit_button} ${scss.action_slow} ${styleClass}`} type="button">
      {text}
    </Button>
  );
};

export const CancelButton: React.FC<Props> = ({ text, onClick, styleClass }) => {
  return (
    <Button type="button" className={`${scss.cancel_button} ${scss.action_slow} ${styleClass}`} onClick={onClick}>
      {text}
    </Button>
  );
};

type SubmitProps = {
  label: string;
  styleClass?: string;
};

export const SubmitButton: React.FC<SubmitProps> = ({ label, styleClass }) => {
  return (
    <Button className={`${scss.button} ${scss.action_slow} ${styleClass}`} type="submit">
      {label}
    </Button>
  );
};

type SubmitLoadingProps = {
  label: string;
  submitting: boolean;
  styleClass?: string;
  disabled?: boolean;
};

export const SubmitLoadingButton: React.FC<SubmitLoadingProps> = ({
  label,
  submitting,
  styleClass,
  disabled = false,
}) => {
  return (
    <Button className={`${scss.button} ${scss.action_slow} ${styleClass}`} type="submit" disabled={disabled}>
      {submitting && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
      {!submitting && label}
    </Button>
  );
};

type AddProps = {
  label: string;
  onClick: () => void;
  disable: boolean;
};
export const AddButton: React.FC<AddProps> = ({ label, onClick, disable }) => {
  return (
    <Button className={disable ? scss.disable_button : scss.button} type="button" onClick={onClick} disabled={disable}>
      {label}
    </Button>
  );
};
