/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AreaListOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationListOutputResponse } from '../domains';
// @ts-ignore
import { CategoryListOutputResponse } from '../domains';
// @ts-ignore
import { ClientAllOutputResponse } from '../domains';
// @ts-ignore
import { ClientOrgShopListOutputResponse } from '../domains';
// @ts-ignore
import { ClientSelectListOutputResponse } from '../domains';
// @ts-ignore
import { EnqueteListOutputResponse } from '../domains';
// @ts-ignore
import { GenreListOutputResponse } from '../domains';
// @ts-ignore
import { MonitorListOutputResponse } from '../domains';
// @ts-ignore
import { QscCategoryListOutputResponse } from '../domains';
// @ts-ignore
import { RecordYmListOutputResponse } from '../domains';
// @ts-ignore
import { SalesRepresentativeListOutputResponse } from '../domains';
/**
 * SelectBoxApi - axios parameter creator
 * @export
 */
export const SelectBoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * エリア一覧を取得する
         * @summary エリア一覧
         * @param {string} [area] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaList: async (area?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/area-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (area !== undefined) {
                localVarQueryParameter['area'] = area;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントのカテゴリを取得する
         * @summary カテゴリ取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryList: async (allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/category-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 全クライアントを取得する
         * @summary 全クライアント取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAll: async (allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント選択を取得する
         * @summary クライアント選択取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSelectList: async (allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-select-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント・組織・店舗リストを取得する
         * @summary クライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientShopList: async (id?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-shop-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート一覧を取得する
         * @summary アンケート一覧取得
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteList: async (ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/enquete-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (enqueteId !== undefined) {
                localVarQueryParameter['enqueteId'] = enqueteId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ジャンル一覧を取得する
         * @summary ジャンル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genreList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/genre-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorList: async (id?: string, ym?: string, monitorId?: string, gPageFlg?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (gPageFlg !== undefined) {
                localVarQueryParameter['gPageFlg'] = gPageFlg;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * QSC項目リストを取得する
         * @summary QSC項目リスト取得
         * @param {string} [cat] 
         * @param {string} [id] 
         * @param {boolean} [commentListFlg] 
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qscCategoryList: async (cat?: string, id?: string, commentListFlg?: boolean, monitorId?: string, gPageFlg?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/qsc-category-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (cat !== undefined) {
                localVarQueryParameter['cat'] = cat;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (commentListFlg !== undefined) {
                localVarQueryParameter['commentListFlg'] = commentListFlg;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (gPageFlg !== undefined) {
                localVarQueryParameter['gPageFlg'] = gPageFlg;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求実績年月一覧を取得する
         * @summary 請求実績年月一覧取得
         * @param {number} billingDestinationId 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBillingYmList: async (billingDestinationId: number, ym?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationId' is not null or undefined
            assertParamExists('recordBillingYmList', 'billingDestinationId', billingDestinationId)
            const localVarPath = `/api/record-billing-ym`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingDestinationId !== undefined) {
                localVarQueryParameter['billingDestinationId'] = billingDestinationId;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実績年月一覧を取得する
         * @summary 実績年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {string} [gPageFlg] 
         * @param {string} [mpPageFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordYmList: async (ym?: string, allClient?: string, gPageFlg?: string, mpPageFlg?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/record-ym`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (gPageFlg !== undefined) {
                localVarQueryParameter['gPageFlg'] = gPageFlg;
            }

            if (mpPageFlg !== undefined) {
                localVarQueryParameter['mpPageFlg'] = mpPageFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 営業担当者一覧を取得する
         * @summary 営業担当者一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesRepresentative: async (allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/salesRepresentative-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [accountid] 
         * @param {string} [accountPage] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountclientShopList: async (id?: string, accountid?: string, accountPage?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/tobaccount-client-shop-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (accountid !== undefined) {
                localVarQueryParameter['accountid'] = accountid;
            }

            if (accountPage !== undefined) {
                localVarQueryParameter['accountPage'] = accountPage;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobBillingDestinationList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-destination-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelectBoxApi - functional programming interface
 * @export
 */
export const SelectBoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelectBoxApiAxiosParamCreator(configuration)
    return {
        /**
         * エリア一覧を取得する
         * @summary エリア一覧
         * @param {string} [area] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areaList(area?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreaListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areaList(area, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントのカテゴリを取得する
         * @summary カテゴリ取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoryList(allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CategoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoryList(allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 全クライアントを取得する
         * @summary 全クライアント取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientAll(allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientAllOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientAll(allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント選択を取得する
         * @summary クライアント選択取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientSelectList(allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSelectListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientSelectList(allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント・組織・店舗リストを取得する
         * @summary クライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientShopList(id?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOrgShopListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientShopList(id, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート一覧を取得する
         * @summary アンケート一覧取得
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteList(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnqueteListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteList(ym, monitorId, enqueteId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ジャンル一覧を取得する
         * @summary ジャンル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async genreList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenreListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.genreList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorList(id?: string, ym?: string, monitorId?: string, gPageFlg?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorList(id, ym, monitorId, gPageFlg, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * QSC項目リストを取得する
         * @summary QSC項目リスト取得
         * @param {string} [cat] 
         * @param {string} [id] 
         * @param {boolean} [commentListFlg] 
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async qscCategoryList(cat?: string, id?: string, commentListFlg?: boolean, monitorId?: string, gPageFlg?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QscCategoryListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.qscCategoryList(cat, id, commentListFlg, monitorId, gPageFlg, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求実績年月一覧を取得する
         * @summary 請求実績年月一覧取得
         * @param {number} billingDestinationId 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordBillingYmList(billingDestinationId: number, ym?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordYmListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordBillingYmList(billingDestinationId, ym, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実績年月一覧を取得する
         * @summary 実績年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {string} [gPageFlg] 
         * @param {string} [mpPageFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recordYmList(ym?: string, allClient?: string, gPageFlg?: string, mpPageFlg?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecordYmListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recordYmList(ym, allClient, gPageFlg, mpPageFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 営業担当者一覧を取得する
         * @summary 営業担当者一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesRepresentative(allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesRepresentativeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesRepresentative(allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [accountid] 
         * @param {string} [accountPage] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobAccountclientShopList(id?: string, accountid?: string, accountPage?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientOrgShopListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobAccountclientShopList(id, accountid, accountPage, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobBillingDestinationList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingDestinationListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobBillingDestinationList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelectBoxApi - factory interface
 * @export
 */
export const SelectBoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelectBoxApiFp(configuration)
    return {
        /**
         * エリア一覧を取得する
         * @summary エリア一覧
         * @param {string} [area] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areaList(area?: string, allClient?: string, options?: any): AxiosPromise<AreaListOutputResponse> {
            return localVarFp.areaList(area, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントのカテゴリを取得する
         * @summary カテゴリ取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoryList(allClient?: string, options?: any): AxiosPromise<Array<CategoryListOutputResponse>> {
            return localVarFp.categoryList(allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 全クライアントを取得する
         * @summary 全クライアント取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientAll(allClient?: string, options?: any): AxiosPromise<Array<ClientAllOutputResponse>> {
            return localVarFp.clientAll(allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント選択を取得する
         * @summary クライアント選択取得
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientSelectList(allClient?: string, options?: any): AxiosPromise<ClientSelectListOutputResponse> {
            return localVarFp.clientSelectList(allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント・組織・店舗リストを取得する
         * @summary クライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientShopList(id?: string, allClient?: string, options?: any): AxiosPromise<ClientOrgShopListOutputResponse> {
            return localVarFp.clientShopList(id, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート一覧を取得する
         * @summary アンケート一覧取得
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteList(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: any): AxiosPromise<EnqueteListOutputResponse> {
            return localVarFp.enqueteList(ym, monitorId, enqueteId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * ジャンル一覧を取得する
         * @summary ジャンル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        genreList(options?: any): AxiosPromise<Array<GenreListOutputResponse>> {
            return localVarFp.genreList(options).then((request) => request(axios, basePath));
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorList(id?: string, ym?: string, monitorId?: string, gPageFlg?: string, allClient?: string, options?: any): AxiosPromise<MonitorListOutputResponse> {
            return localVarFp.monitorList(id, ym, monitorId, gPageFlg, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * QSC項目リストを取得する
         * @summary QSC項目リスト取得
         * @param {string} [cat] 
         * @param {string} [id] 
         * @param {boolean} [commentListFlg] 
         * @param {string} [monitorId] 
         * @param {string} [gPageFlg] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        qscCategoryList(cat?: string, id?: string, commentListFlg?: boolean, monitorId?: string, gPageFlg?: string, allClient?: string, options?: any): AxiosPromise<QscCategoryListOutputResponse> {
            return localVarFp.qscCategoryList(cat, id, commentListFlg, monitorId, gPageFlg, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求実績年月一覧を取得する
         * @summary 請求実績年月一覧取得
         * @param {number} billingDestinationId 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordBillingYmList(billingDestinationId: number, ym?: string, options?: any): AxiosPromise<RecordYmListOutputResponse> {
            return localVarFp.recordBillingYmList(billingDestinationId, ym, options).then((request) => request(axios, basePath));
        },
        /**
         * 実績年月一覧を取得する
         * @summary 実績年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {string} [gPageFlg] 
         * @param {string} [mpPageFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recordYmList(ym?: string, allClient?: string, gPageFlg?: string, mpPageFlg?: string, options?: any): AxiosPromise<RecordYmListOutputResponse> {
            return localVarFp.recordYmList(ym, allClient, gPageFlg, mpPageFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 営業担当者一覧を取得する
         * @summary 営業担当者一覧
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesRepresentative(allClient?: string, options?: any): AxiosPromise<Array<SalesRepresentativeListOutputResponse>> {
            return localVarFp.salesRepresentative(allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * TobAccountクライアント・組織・店舗リストを取得する
         * @summary TobAccountクライアント・組織・店舗リスト取得
         * @param {string} [id] 
         * @param {string} [accountid] 
         * @param {string} [accountPage] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobAccountclientShopList(id?: string, accountid?: string, accountPage?: string, allClient?: string, options?: any): AxiosPromise<ClientOrgShopListOutputResponse> {
            return localVarFp.tobAccountclientShopList(id, accountid, accountPage, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobBillingDestinationList(options?: any): AxiosPromise<Array<BillingDestinationListOutputResponse>> {
            return localVarFp.tobBillingDestinationList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelectBoxApi - object-oriented interface
 * @export
 * @class SelectBoxApi
 * @extends {BaseAPI}
 */
export class SelectBoxApi extends BaseAPI {
    /**
     * エリア一覧を取得する
     * @summary エリア一覧
     * @param {string} [area] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public areaList(area?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).areaList(area, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントのカテゴリを取得する
     * @summary カテゴリ取得
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public categoryList(allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).categoryList(allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 全クライアントを取得する
     * @summary 全クライアント取得
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public clientAll(allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).clientAll(allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント選択を取得する
     * @summary クライアント選択取得
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public clientSelectList(allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).clientSelectList(allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント・組織・店舗リストを取得する
     * @summary クライアント・組織・店舗リスト取得
     * @param {string} [id] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public clientShopList(id?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).clientShopList(id, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート一覧を取得する
     * @summary アンケート一覧取得
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [enqueteId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public enqueteList(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).enqueteList(ym, monitorId, enqueteId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ジャンル一覧を取得する
     * @summary ジャンル一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public genreList(options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).genreList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター一覧を取得する
     * @summary モニター一覧
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] 
     * @param {string} [gPageFlg] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public monitorList(id?: string, ym?: string, monitorId?: string, gPageFlg?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).monitorList(id, ym, monitorId, gPageFlg, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * QSC項目リストを取得する
     * @summary QSC項目リスト取得
     * @param {string} [cat] 
     * @param {string} [id] 
     * @param {boolean} [commentListFlg] 
     * @param {string} [monitorId] 
     * @param {string} [gPageFlg] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public qscCategoryList(cat?: string, id?: string, commentListFlg?: boolean, monitorId?: string, gPageFlg?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).qscCategoryList(cat, id, commentListFlg, monitorId, gPageFlg, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求実績年月一覧を取得する
     * @summary 請求実績年月一覧取得
     * @param {number} billingDestinationId 
     * @param {string} [ym] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public recordBillingYmList(billingDestinationId: number, ym?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).recordBillingYmList(billingDestinationId, ym, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実績年月一覧を取得する
     * @summary 実績年月一覧取得
     * @param {string} [ym] 
     * @param {string} [allClient] 
     * @param {string} [gPageFlg] 
     * @param {string} [mpPageFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public recordYmList(ym?: string, allClient?: string, gPageFlg?: string, mpPageFlg?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).recordYmList(ym, allClient, gPageFlg, mpPageFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 営業担当者一覧を取得する
     * @summary 営業担当者一覧
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public salesRepresentative(allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).salesRepresentative(allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * TobAccountクライアント・組織・店舗リストを取得する
     * @summary TobAccountクライアント・組織・店舗リスト取得
     * @param {string} [id] 
     * @param {string} [accountid] 
     * @param {string} [accountPage] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public tobAccountclientShopList(id?: string, accountid?: string, accountPage?: string, allClient?: string, options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).tobAccountclientShopList(id, accountid, accountPage, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先一覧を取得する
     * @summary 請求送付先一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectBoxApi
     */
    public tobBillingDestinationList(options?: AxiosRequestConfig) {
        return SelectBoxApiFp(this.configuration).tobBillingDestinationList(options).then((request) => request(this.axios, this.basePath));
    }
}

