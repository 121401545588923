/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiLoginPostRequestResponse } from '../domains';
/**
 * LoginEndpointApi - axios parameter creator
 * @export
 */
export const LoginEndpointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApiLoginPostRequestResponse} [apiLoginPostRequestResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoginPost: async (apiLoginPostRequestResponse?: ApiLoginPostRequestResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiLoginPostRequestResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginEndpointApi - functional programming interface
 * @export
 */
export const LoginEndpointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginEndpointApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApiLoginPostRequestResponse} [apiLoginPostRequestResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLoginPost(apiLoginPostRequestResponse?: ApiLoginPostRequestResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLoginPost(apiLoginPostRequestResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginEndpointApi - factory interface
 * @export
 */
export const LoginEndpointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginEndpointApiFp(configuration)
    return {
        /**
         * 
         * @param {ApiLoginPostRequestResponse} [apiLoginPostRequestResponse] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLoginPost(apiLoginPostRequestResponse?: ApiLoginPostRequestResponse, options?: any): AxiosPromise<string> {
            return localVarFp.apiLoginPost(apiLoginPostRequestResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginEndpointApi - object-oriented interface
 * @export
 * @class LoginEndpointApi
 * @extends {BaseAPI}
 */
export class LoginEndpointApi extends BaseAPI {
    /**
     * 
     * @param {ApiLoginPostRequestResponse} [apiLoginPostRequestResponse] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginEndpointApi
     */
    public apiLoginPost(apiLoginPostRequestResponse?: ApiLoginPostRequestResponse, options?: AxiosRequestConfig) {
        return LoginEndpointApiFp(this.configuration).apiLoginPost(apiLoginPostRequestResponse, options).then((request) => request(this.axios, this.basePath));
    }
}

