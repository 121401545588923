import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import scss from '../../scss/pages/initpass.module.scss';
import { Url } from '../../constants/Url';
import { InitPasswordFormResponse, InitPasswordUpdateControllerApi, ResultOutputResponse } from '../../api-client';
import { Alert } from '../atoms/Alert';
import { CurrentUser } from '../../interfaces/user';
import { currentUserState } from '../../states/atom/CurrentUserState';
import layoutScss from '../../scss/templates/mainLayout.module.scss';
import { getUser } from '../../services/AuthService';
import { ConfirmModal } from '../organisms/ConfirmModal';
import { openConfirmModal } from '../../states/atom/ConfirmModalOpen';

const InitPassPage: React.FC = () => {
  const api = new InitPasswordUpdateControllerApi();
  // URL情報取得
  const { currentUser, isAuthChecking } = useCurrentUser();
  const setCurrentUser = useSetRecoilState(currentUserState);
  // パスワード表示非表示制御
  const [isRevealNowPassword, setIsRevealNowPassword] = useState(false);
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
  const [isRevealConfPassword, setIsRevealConfPassword] = useState(false);
  // 処理結果
  const [updateResult, setUpdateResult] = useState<boolean>(true);
  // モーダル
  const [open, setOpen] = useRecoilState(openConfirmModal);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<InitPasswordFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  // React-Router-Domの機能
  const history = useHistory();

  const toggleNowPassword = () => {
    setIsRevealNowPassword((prevState) => !prevState);
  };
  const toggleNewPassword = () => {
    setIsRevealNewPassword((prevState) => !prevState);
  };
  const toggleConfPassword = () => {
    setIsRevealConfPassword((prevState) => !prevState);
  };

  useEffect(() => {
    // 既に初期パスワードを変更済み
    if (currentUser?.firstPasswordFlg === false) {
      history.push(Url.SCORE_VIEW);
    }
  }, []);

  const onSubmit: SubmitHandler<InitPasswordFormResponse> = async (data: InitPasswordFormResponse) => {
    await api.update(data).then(async (res) => {
      if (res != null) {
        const resultRes = res.data as ResultOutputResponse;
        // エラーが無かった場合
        if (resultRes.result) {
          // ユーザ情報取得
          await getUser().then((user) => {
            if (user != null && user !== false) {
              const auth = user as CurrentUser;
              setCurrentUser(auth);
              setOpen(true);
            }
          });
        } else {
          setUpdateResult(false);
        }
      }
    });
  };

  return (
    <>
      {!isAuthChecking ? (
        <Container
          fluid
          className={`${scss.result_group} ${layoutScss.more_narrow} ${scss.content_under_margin} ${scss.content_top_margin}`}
        >
          <Card className={`${scss.top_lg_space}`}>
            <Card.Body>
              <h3 className={`${scss.simple_h2}`}>初期パスワード変更</h3>
              <p className={`${scss.text_p} ${scss.text_bt_mg}`}>
                初めてログインされた場合は、セキュリティ向上のため初期パスワードの変更を行ってください。
                <br />
                変更後はこの画面は表示されません。
                <br />
                パスワードは8文字以上で、使用できる文字は英数字(A-Z、a-z、0-9)です。
                <br />
                また必ず英字、数字それぞれ1文字以上含んでいる必要があります。
                <br />
                ※新しいパスワードは次回ログイン時に使用しますので、お忘れにならないようご注意ください。
              </p>
              {!updateResult && (
                <div>
                  <Alert variant="danger">
                    <span>初期パスワードの変更に失敗しました。</span>
                  </Alert>
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div className={`${scss.value_wrapper}`}>
                    <p className={`${scss.label}`}>ユーザ名</p>
                    <p className={`${scss.value_text}`}>{`${currentUser?.name}`}</p>
                  </div>
                  <div className={`${scss.value_wrapper}`}>
                    <p className={`${scss.label}`}>メールアドレス</p>
                    <p className={`${scss.value_text}`}>{`${currentUser?.email}`}</p>
                  </div>
                  <div className={`${scss.value_wrapper}`}>
                    <p className={`${scss.label}`}>現在のパスワード</p>
                    <div className={`${scss.block_relative}`}>
                      <Form.Control
                        {...register('nowPassword', {
                          required: {
                            value: true,
                            message: 'ユーザ名は必須です。',
                          },
                          // pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'メールアドレスの形式が間違っています。'},
                          minLength: {
                            value: 1,
                            message: '1文字以上入力してください。',
                          },
                        })}
                        type={isRevealNowPassword ? 'text' : 'password'}
                        className={`form-control ${errors.nowPassword && 'is-invalid'}`}
                      />
                      <span onClick={toggleNowPassword} role="presentation" className={scss.password_reveal}>
                        {isRevealNowPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    {errors.nowPassword && (
                      <div>
                        <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
                          <span>{errors.nowPassword.message}</span>
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className={`${scss.value_wrapper} `}>
                    <p className={`${scss.label}`}>新しいパスワード</p>
                    <div className={`${scss.block_relative}`}>
                      <Form.Control
                        {...register('newPassword', {
                          required: {
                            value: true,
                            message: '新しいパスワードは必須です。',
                          },
                          // pattern: { value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/, message: 'パスワードの形式が間違っています'},
                          pattern: {
                            value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/,
                            message: 'パスワードの形式が間違っています',
                          },
                          minLength: {
                            value: 8,
                            message: '8文字以上入力してください。',
                          },
                          validate: (value) => {
                            const now = getValues('nowPassword');
                            return value !== now || '同じパスワードは設定できません';
                          },
                        })}
                        type={isRevealNewPassword ? 'text' : 'password'}
                        className={`form-control ${errors.newPassword && 'is-invalid'}`}
                      />
                      <span onClick={toggleNewPassword} role="presentation" className={scss.password_reveal}>
                        {isRevealNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    {errors.newPassword && (
                      <div>
                        <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
                          <span>{errors.newPassword.message}</span>
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className={`${scss.value_wrapper}`}>
                    <p className={`${scss.label}`}>確認パスワード</p>
                    <div className={`${scss.block_relative}`}>
                      <Form.Control
                        {...register('confirmPassword', {
                          required: {
                            value: true,
                            message: '確認パスワードは必須です。',
                          },
                          validate: (value) => {
                            return value === getValues('newPassword') || 'パスワードが一致しません';
                          },
                        })}
                        type={isRevealConfPassword ? 'text' : 'password'}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                      />
                      <span onClick={toggleConfPassword} role="presentation" className={scss.password_reveal}>
                        {isRevealConfPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <div>
                        <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
                          <span>{errors.confirmPassword.message}</span>
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className={`${scss.btn_shadow}`}>
                    <Button className={`${scss.button} ${scss.action_slow} ${scss.btn_shadow}`} type="submit">
                      変更
                    </Button>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Container>
      ) : null}
      <ConfirmModal title="初期パスワード変更" content="初期パスワードの変更が完了しました。" url={Url.SCORE_VIEW} />
    </>
  );
};

export default InitPassPage;
