import React, { MouseEvent, useEffect, useState } from 'react';
import { Nav, Navbar, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaRegBell, FaSignOutAlt, FaUsers } from 'react-icons/fa';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { Url } from '../../constants/Url';
import scss from '../../scss/templates/headerlayout.module.scss';
import Logo from '../../img/layout/logo.svg';
import { CorporateSelect } from '../molecules/Form/CorporateSelect';
import { NewwsPopover } from '../organisms/Popover/NewsPopover';
import { sidebarState } from '../../states/atom/CommonPageState';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { currentUserState } from '../../states/atom/CurrentUserState';
import { logout } from '../../services/AuthService';

type Props = {
  badge: boolean;
};

export const HeaderLayout: React.FC<Props> = ({ badge }) => {
  const history = useHistory();
  const { roiAdminFlg, name, isAuthChecking } = useCurrentUser();
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  const [header, setHeader] = useState(true);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const resetCurrentUser = useResetRecoilState(currentUserState);
  const handleLogout = async () => {
    logout();
    resetCurrentUser();
    history.push(Url.LOGOUT);
  };

  /**
   * スーパーユーザーのクライアント選択画面へ
   */
  const handleClientSelect = async () => {
    setCurrentUser((prev) => ({ ...prev!, clientSelected: false }));
    history.push(Url.CLIENT_SELECT);
  };

  /* アカウントアイコンポップオーバーの中身 */
  const userIconPopover = (
    <Popover id="popover-user">
      <Popover.Body>
        <a href="#" className="d-block text-decoration-none text-center mb-2">
          アカウント設定
        </a>
        <a href="#" className="d-block text-decoration-none text-center" onClick={() => handleLogout()}>
          ログアウト
        </a>
      </Popover.Body>
    </Popover>
  );
  /* お知らせアイコンポップオーバーの中身 */
  const bellIconPopover = (
    <Popover style={{ minWidth: '500px', maxWidth: '500px', minHeight: '300px' }}>
      <Popover.Body>
        <NewwsPopover />
      </Popover.Body>
    </Popover>
  );

  let lastScrollY = 0;
  const handleScroll = () => {
    setHeader(window.scrollY - lastScrollY < 0);
    lastScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // アンマウント時の設定
  }, []);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSidebar(!sidebar);
  };

  return (
    <>
      <header className={`${scss.wrapper} ${scss.header_nav} `}>
        <Navbar className={`p-0 shadow ${scss.nav_bar}`} collapseOnSelect expand="lg" bg="info" sticky="top">
          <Nav className={`${scss.nav_buttom}`} style={{ marginLeft: '0.5rem' }}>
            <Nav.Link href="#" onClick={handleClick}>
              <div className={`${scss.hamburger_nemu} ${sidebar ? scss.hamburger_nemu_close : ''}`}>
                <span />
                <span />
                <span />
              </div>
            </Nav.Link>
          </Nav>

          <Nav className={`${scss.wrap_logo} d-flex align-items-center`}>
            <Nav.Link
              href="#"
              onClick={() => {
                history.push(Url.SCORE_VIEW);
              }}
            >
              <Navbar.Brand>
                <img src={Logo} alt="fancrew" className={`${scss.logo}`} />
              </Navbar.Brand>
            </Nav.Link>
          </Nav>
          {/** me-autoで左寄せ */}
          <Nav className={`${scss.admin_select} me-auto`}>
            {roiAdminFlg && (
              <Nav.Item>
                <CorporateSelect />
              </Nav.Item>
            )}
          </Nav>
          {false && (
            <Nav className={`${scss.sp_none} ${scss.bell_icon_warpper}`}>
              {/** rootCloseを入れると、ツールチップ外をタップすることで閉じられる */}
              <OverlayTrigger trigger="click" placement="bottom-end" overlay={bellIconPopover} rootClose>
                <div className={`${badge && scss.icon_badge}`} style={{ cursor: 'pointer' }}>
                  <IconContext.Provider value={{ size: '1.5rem' }}>
                    <FaRegBell />
                  </IconContext.Provider>
                </div>
              </OverlayTrigger>
            </Nav>
          )}
          {!isAuthChecking && (
            <div className={`${scss.header_name}`}>
              <span>{name}</span>
            </div>
          )}
          {currentUser?.clientSelect && (
            <Nav className={`${scss.button_logout}`} style={{ marginRight: '0.5rem' }}>
              <Nav.Link href="#" onClick={() => handleClientSelect()}>
                <div className="d-flex align-items-center">
                  <IconContext.Provider value={{ size: '1.125rem' }}>
                    <FaUsers />
                  </IconContext.Provider>
                </div>
              </Nav.Link>
            </Nav>
          )}
          <Nav className={`${scss.button_logout}`} style={{ marginRight: '0.5rem' }}>
            <Nav.Link href="#" onClick={() => handleLogout()}>
              <div className="d-flex align-items-center">
                <IconContext.Provider value={{ size: '1.125rem' }}>
                  <FaSignOutAlt />
                </IconContext.Provider>
              </div>
            </Nav.Link>
          </Nav>
        </Navbar>
      </header>
    </>
  );
};
