import * as React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

interface Props {
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'link';
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: (() => void) | undefined;
  href?: string;
  testId?: string;
}

export const Button: React.FC<Props> = ({ children, testId, ...rest }) => {
  return (
    <BootstrapButton data-testid={testId} {...rest}>
      {children}
    </BootstrapButton>
  );
};

Button.defaultProps = {
  variant: 'primary',
  className: undefined,
  type: undefined,
  onClick: undefined,
  href: undefined,
  testId: undefined,
};
