import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
  variant?: 'primary' | 'success' | 'danger';
  styleName?: string;
}

export const Alert: React.FC<Props> = ({ children, variant, styleName }): JSX.Element => {
  return (
    <BootstrapAlert variant={variant} className={styleName}>
      {children}
    </BootstrapAlert>
  );
};

Alert.defaultProps = {
  variant: 'primary',
};
