import React, { Dispatch, SetStateAction } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { yupResolver } from '@hookform/resolvers/yup';
import { openModal } from '../../states/atom/InquiryModalOpen';
import InquiryForm from './Form/InquiryForm';
import scss from '../../scss/organisms/inquiry-modal.module.scss';
import { SubmitButton } from '../atoms/Button/UnitButton';
import { schema } from '../../services/inquiryFormValidation';
import { InquieryApi, InquryUpdateFormResponse } from '../../api-client';

const InquiryModal: React.FC = () => {
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<InquryUpdateFormResponse>({
    resolver: yupResolver(schema),
  });
  const [open, setOpen] = useRecoilState(openModal);

  const api = new InquieryApi();

  const onSubmit = (data: InquryUpdateFormResponse) => {
    api
      .inquryCreate({
        name: data.name,
        mailaddress: data.mailaddress,
        inquiryCategoryId: Number(data.inquiryCategoryId),
        telNumber: data.telNumber,
        content: data.content,
      })
      .then((res) => {
        setValue('name', '');
        setValue('mailaddress', '');
        setValue('inquiryCategoryId', 1);
        setValue('telNumber', '');
        setValue('content', '');
        setOpen(false);
      });
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)} className={scss.modal}>
      <Modal.Header className={scss.modal_header} closeButton>
        <Modal.Title>お問い合わせ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="my-3" style={{ fontSize: '14px' }}>
          本サービスに関するお問い合わせはこちらからお願いいたします。
        </div>
        <InquiryForm register={register} errors={errors} setValue={setValue} />
      </Modal.Body>
      <Modal.Footer className="border-0 m-0 d-block">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <SubmitButton label="送信する" />
        </Form>
      </Modal.Footer>
    </Modal>
  );
};

export default InquiryModal;
