import React, { memo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AccountApi } from '../../api-client';
import scss from '../../scss/organisms/registerUser.module.scss';
import layoutScss from '../../scss/templates/mainLayout.module.scss';
import { TITLE } from '../../constants/Title';
import { UnitButton } from '../atoms/Button/UnitButton';
import { Url } from '../../constants/Url';
import { Title } from '../atoms/Title';
import { useUrlParams } from '../../hooks/useUrlParams';
import { SuspendPromise } from '../../services/SuspendPromise';
import { RegistUserForm } from '../organisms/Form/RegistUserForm';
import { EditAccount } from '../organisms/UserManagement/EditAccount';

const MyEditUserPage: React.FC = memo(() => {
  const history = useHistory();
  const handleTransition = () => {
    history.push(Url.USER_LIST);
  };

  const params = useUrlParams();

  const fetch = () => {
    const api = new AccountApi();
    return api.tobAccountInfo();
  };

  const resource = SuspendPromise(fetch());
  const Wrap = () => {
    const { exist, account } = resource.read();
    return <>{exist && <EditAccount account={account} />}</>;
  };

  return (
    <>
      <Container fluid className={`${scss.form_group}`}>
        <Row>
          <Col md="auto">
            <Title className="mt-2">{TITLE.TOB.ACCOUNT_EDIT}</Title>
          </Col>
          <Col>
            <RegistUserForm />
          </Col>
        </Row>
        <div className={`${scss.btn_block} ${scss.sp_none}`}>
          <UnitButton text="キャンセル" onClick={handleTransition} />
        </div>
      </Container>
      <Container
        fluid
        className={`${scss.result_group} ${layoutScss.narrow} ${scss.content_under_margin}`}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Wrap />
      </Container>
    </>
  );
});

export default MyEditUserPage;
