/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EnqueteAnswerListOutputResponse } from '../domains';
// @ts-ignore
import { EnqueteContentListOutputResponse } from '../domains';
/**
 * EnqueteApi - axios parameter creator
 * @export
 */
export const EnqueteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 設問情報を取得する
         * @summary 設問情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerList: async (id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/enquete-answer-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (applyIds !== undefined) {
                localVarQueryParameter['applyIds'] = applyIds;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート情報を取得する
         * @summary アンケート情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerListExcel: async (id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/enquete-answer-list-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (applyIds !== undefined) {
                localVarQueryParameter['applyIds'] = applyIds;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート内容を確認
         * @summary アンケート内容確認
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteContent: async (ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/enquete-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (enqueteId !== undefined) {
                localVarQueryParameter['enqueteId'] = enqueteId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケートExcelを取得する
         * @summary アンケートExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteExcel: async (id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/enquete-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (averageType !== undefined) {
                localVarQueryParameter['averageType'] = averageType;
            }

            if (averagePeriod !== undefined) {
                localVarQueryParameter['averagePeriod'] = averagePeriod;
            }

            if (rateFormatType !== undefined) {
                localVarQueryParameter['rateFormatType'] = rateFormatType;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnqueteApi - functional programming interface
 * @export
 */
export const EnqueteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnqueteApiAxiosParamCreator(configuration)
    return {
        /**
         * 設問情報を取得する
         * @summary 設問情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteAnswerList(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnqueteAnswerListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteAnswerList(id, ym, monitorId, applyIds, allClient, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート情報を取得する
         * @summary アンケート情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteAnswerListExcel(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteAnswerListExcel(id, ym, monitorId, applyIds, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート内容を確認
         * @summary アンケート内容確認
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteContent(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnqueteContentListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteContent(ym, monitorId, enqueteId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケートExcelを取得する
         * @summary アンケートExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnqueteApi - factory interface
 * @export
 */
export const EnqueteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnqueteApiFp(configuration)
    return {
        /**
         * 設問情報を取得する
         * @summary 設問情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerList(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, page?: number, options?: any): AxiosPromise<EnqueteAnswerListOutputResponse> {
            return localVarFp.enqueteAnswerList(id, ym, monitorId, applyIds, allClient, page, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート情報を取得する
         * @summary アンケート情報取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [applyIds] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerListExcel(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.enqueteAnswerListExcel(id, ym, monitorId, applyIds, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート内容を確認
         * @summary アンケート内容確認
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [enqueteId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteContent(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: any): AxiosPromise<EnqueteContentListOutputResponse> {
            return localVarFp.enqueteContent(ym, monitorId, enqueteId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケートExcelを取得する
         * @summary アンケートExcel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.enqueteExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnqueteApi - object-oriented interface
 * @export
 * @class EnqueteApi
 * @extends {BaseAPI}
 */
export class EnqueteApi extends BaseAPI {
    /**
     * 設問情報を取得する
     * @summary 設問情報取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [applyIds] 
     * @param {string} [allClient] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteApi
     */
    public enqueteAnswerList(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, page?: number, options?: AxiosRequestConfig) {
        return EnqueteApiFp(this.configuration).enqueteAnswerList(id, ym, monitorId, applyIds, allClient, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート情報を取得する
     * @summary アンケート情報取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [applyIds] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteApi
     */
    public enqueteAnswerListExcel(id?: string, ym?: string, monitorId?: string, applyIds?: string, allClient?: string, options?: AxiosRequestConfig) {
        return EnqueteApiFp(this.configuration).enqueteAnswerListExcel(id, ym, monitorId, applyIds, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート内容を確認
     * @summary アンケート内容確認
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [enqueteId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteApi
     */
    public enqueteContent(ym?: string, monitorId?: string, enqueteId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return EnqueteApiFp(this.configuration).enqueteContent(ym, monitorId, enqueteId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケートExcelを取得する
     * @summary アンケートExcel
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [averageType] 
     * @param {string} [averagePeriod] 
     * @param {string} [rateFormatType] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteApi
     */
    public enqueteExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig) {
        return EnqueteApiFp(this.configuration).enqueteExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

