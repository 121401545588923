import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import scss from '../../scss/templates/layout.module.scss';
import { Head } from '../organisms/Head';
import { sidebarState } from '../../states/atom/CommonPageState';

type Props = {
  title: string;
};

export const NoDesignLayout: React.FC<Props> = ({ title, children }) => {
  const { pathname } = useLocation();
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  // const isSpScreen: boolean = useMediaQuery({ query: '(max-width: 767px)' });
  const isTabletScreen: boolean = useMediaQuery({ query: '(max-width: 991px)' });

  const [badge, setBadge] = useState(false);

  return (
    <>
      <Head title={title} />
      <div className={`${scss.wrapper_header2}`}>
        <div>{children}</div>
      </div>
    </>
  );
};
