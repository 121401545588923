import { useLocation } from 'react-router-dom';
import { useUrlParams } from './useUrlParams';
import { ImageObj } from '../interfaces/ImageObj';

export const useParamImage = () => {
  const { pathname, search } = useLocation();
  const params = useUrlParams();
  let paramPath: string | null = null;
  let paramWidth: string | null = null;
  let paramHeight: string | null = null;
  if (params.path !== undefined && params.path.length > 0) {
    paramPath = params.path;
  }
  if (params.width !== undefined && params.width.length > 0) {
    paramWidth = params.width;
  }
  if (params.height !== undefined && params.height.length > 0) {
    paramHeight = params.height;
  }

  return { path: paramPath, height: paramHeight, width: paramWidth } as ImageObj;
};
