import { atom, atomFamily } from 'recoil';
import { RecoilAtomKeys } from '../RecoilKeys';
import {
  ClientOrgShopListOutputResponse,
  TobAccountInfoOutputResponse,
  TobAccountListOutputRowDataResponse,
  TobAccountSubListOutputResponse,
} from '../../api-client';
import { SearchTexts } from '../RecoilType';
import { ClientType, SelectType } from '../../components/molecules/Form/SubBelongSelectGroup';

export const mainSelectedAuthState = atomFamily<string, string>({
  key: RecoilAtomKeys.MAIN_SELECTED_AUTH_STATE,
  default: '',
});

export const subSelectedAuthState = atomFamily<string, string>({
  key: RecoilAtomKeys.SUB_SELECTED_AUTH_STATE,
  default: '1',
});

export const mainSelectedBelongState = atomFamily<string, string>({
  key: RecoilAtomKeys.MAIN_SELECTED_BELONG_STATE,
  default: '',
});

// 所属選択済みフラグ
export const subSelectedListState = atomFamily<SelectType[], string>({
  key: RecoilAtomKeys.SUB_SELECTED_LIST_STATE,
  default: [],
});

// 所属選択済みフラグ
export const mainBelongEditFlgState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.MAIN_BELONG_EDIT_FLG_STATE,
  default: false,
});
// サブ所属選択済みフラグ
export const subEditFlgState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.SUB_EDIT_FLG_STATE,
  default: false,
});

export const mainSelectedOrgLevelState = atomFamily<number, string>({
  key: RecoilAtomKeys.MAIN_SELECTED_ORG_LEVEL_STATE,
  default: 0,
});

export const subSelectedBelongState = atomFamily<string, string>({
  key: RecoilAtomKeys.SUB_SELECTED_BELONG_STATE,
  default: '',
});

export const openSubBelongOpenState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.OPEN_SUB_BELONG_MODAL_STATE,
  default: false,
});

export const mainBelongListState = atomFamily<ClientOrgShopListOutputResponse | null, string>({
  key: RecoilAtomKeys.MAIN_BELONG_LIST_STATE,
  default: null,
});

export const belongDataListState = atomFamily<TobAccountSubListOutputResponse | null, string>({
  key: RecoilAtomKeys.BELONG_DATA_LIST_STATE,
  default: null,
});

export const belongRadioSelectedFlgState = atomFamily<string | null, string>({
  key: RecoilAtomKeys.BELONG_RADIO_SELECTED_FLG_STATE,
  default: null,
});

export const sendMailState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.SEND_MAIL_STATE,
  default: false,
});

export const userListState = atom<Array<TobAccountListOutputRowDataResponse> | null>({
  key: RecoilAtomKeys.USER_LIST_STATE,
  default: null,
});

// export const updateUserState = atom<TobAccountUpdateFormResponse>({
//   key: RecoilAtomKeys.UPDATE_USER_STATE,
//   default: {
//     belong: '',
//     clientAdminFlg: false,
//     confirmePassword: '',
//     giftViewFlg: false,
//     goalSetFlg: false,
//     id: '',
//     initPassword: false,
//     mailSendFlg: false,
//     mailaddress: '',
//     name: '',
//     password: '',
//     roiAdminFlg: false,
//     userEditFlg: false,
//     viewAllFlg: false,
//   },
// });

export const searchUserFormState = atom<SearchTexts>({
  key: RecoilAtomKeys.SEARCH_USER_FORM_STATE,
  default: {
    name: '',
    email: '',
    belong: '',
  },
});

export const editUserInfoState = atomFamily<TobAccountInfoOutputResponse | null, string>({
  key: RecoilAtomKeys.EDIT_USER_INFO_STATE,
  default: null,
});

export const subBelongListState = atomFamily<TobAccountSubListOutputResponse | null, string>({
  key: RecoilAtomKeys.SUB_BELONG_LIST_STATE,
  default: null,
});
