/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DigitalTicketListOutputResponse } from '../domains';
// @ts-ignore
import { QuestionCategoryListOutputResponse } from '../domains';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ギフト券一覧を取得する
         * @summary ギフト券一覧取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [giftId] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalTicketList: async (id?: string, ym?: string, giftId?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/digital-ticket-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (giftId !== undefined) {
                localVarQueryParameter['giftId'] = giftId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 評価・設問カテゴリ情報を取得する
         * @summary 評価・設問カテゴリ情報取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {string} [commentListFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList: async (monitorId?: number, allClient?: string, commentListFlg?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/question-category-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }

            if (commentListFlg !== undefined) {
                localVarQueryParameter['commentListFlg'] = commentListFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * ギフト券一覧を取得する
         * @summary ギフト券一覧取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [giftId] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async digitalTicketList(id?: string, ym?: string, giftId?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DigitalTicketListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.digitalTicketList(id, ym, giftId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 評価・設問カテゴリ情報を取得する
         * @summary 評価・設問カテゴリ情報取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {string} [commentListFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionCategoryList(monitorId?: number, allClient?: string, commentListFlg?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionCategoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionCategoryList(monitorId, allClient, commentListFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * ギフト券一覧を取得する
         * @summary ギフト券一覧取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [giftId] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        digitalTicketList(id?: string, ym?: string, giftId?: string, status?: string, options?: any): AxiosPromise<Array<DigitalTicketListOutputResponse>> {
            return localVarFp.digitalTicketList(id, ym, giftId, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 評価・設問カテゴリ情報を取得する
         * @summary 評価・設問カテゴリ情報取得
         * @param {number} [monitorId] モニターベースID
         * @param {string} [allClient] 
         * @param {string} [commentListFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList(monitorId?: number, allClient?: string, commentListFlg?: string, options?: any): AxiosPromise<Array<QuestionCategoryListOutputResponse>> {
            return localVarFp.questionCategoryList(monitorId, allClient, commentListFlg, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * ギフト券一覧を取得する
     * @summary ギフト券一覧取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [giftId] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public digitalTicketList(id?: string, ym?: string, giftId?: string, status?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).digitalTicketList(id, ym, giftId, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 評価・設問カテゴリ情報を取得する
     * @summary 評価・設問カテゴリ情報取得
     * @param {number} [monitorId] モニターベースID
     * @param {string} [allClient] 
     * @param {string} [commentListFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public questionCategoryList(monitorId?: number, allClient?: string, commentListFlg?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).questionCategoryList(monitorId, allClient, commentListFlg, options).then((request) => request(this.axios, this.basePath));
    }
}

