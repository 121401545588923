import React, { useLayoutEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { getUser } from '../../services/AuthService';
import { currentUserState } from '../../states/atom/CurrentUserState';
import { Layout } from '../templates/Layout';
import { Url } from '../../constants/Url';
import { LoginLayout } from '../templates/LoginLayout';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import ErrorAuthPage from '../pages/ErrorAuthPage';
import { redirectUrlAfterLoginState } from '../../states/atom/RedirectUrlAfterLoginState';

type Props = {
  path: string;
  title: string;
};

const userEditPage: string[] = [Url.USER_LIST, Url.REGISTER_USER, Url.EDIT_USER];

const goalSetPage: string[] = [Url.SHOP_CATEGORY_GOAL_SETTING, Url.SHOP_GOAL_SETTING];

export const PrivateRoute: React.FC<Props> = ({ path, title, children }) => {
  const { userEditFlg, goalSetFlg, firstPasswordFlg } = useCurrentUser();

  const history = useHistory();
  const { pathname } = useLocation();
  const setCurrentUser = useSetRecoilState(currentUserState);
  const setRedirectUrlAfterLogin = useSetRecoilState(redirectUrlAfterLoginState);
  useLayoutEffect(() => {
    // ログアウトページの場合は無視する
    if (pathname !== Url.LOGOUT) {
      // 認証されているかチェックする
      getUser()
        .then((result) => {
          if (typeof result === 'object') {
            setCurrentUser(result);
            // 初期パスワードの場合は初期パスワード変更画面を表示する
            if (firstPasswordFlg) {
              history.push(Url.INIT_PASSWORD);
            }
          } else {
            setRedirectUrlAfterLogin({ path: pathname });
            setCurrentUser(null);
            // history.push(Url.LOGIN);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    }
  }, [pathname]); // ユーザ一覧で権限変更された場合の即時反映

  let authCheck = true;
  if (userEditPage.find((it) => it === pathname)) {
    authCheck = userEditFlg;
  }
  if (goalSetPage.find((it) => it === pathname)) {
    authCheck = goalSetFlg;
  }
  return (
    <>
      {pathname === Url.LOGOUT ? (
        <LoginLayout title={title}>
          <Route path={path}>{children}</Route>
        </LoginLayout>
      ) : (
        <>
          {authCheck ? (
            <Layout title={title}>
              <Route path={path}>{children}</Route>
            </Layout>
          ) : (
            <Layout title={title}>
              <ErrorAuthPage title={title} />
            </Layout>
          )}
        </>
      )}
    </>
  );
};
