import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { useUrlParams } from './useUrlParams';
import { allClientState } from '../states/atom/CommonPageState';

export const useParamClientAll = () => {
  const { pathname, search } = useLocation();
  const params = useUrlParams();
  const [allClient, setAllClient] = useRecoilState(allClientState(''));
  let paramClientAll = '';
  // URLにパラメータが無い場合
  if (
    ((params.allClient === undefined || params.allClient.length === 0) && allClient !== undefined) ||
    (params.allClient !== undefined && allClient !== undefined && params.allClient === allClient)
  ) {
    paramClientAll = allClient;
    // paramClientAll = undefined;
  } else if (params.allClient !== undefined && params.allClient.length > 0) {
    paramClientAll = params.allClient;
  }

  return paramClientAll;
};
