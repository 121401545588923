import React from 'react';
import { Nav } from 'react-bootstrap';
import scss from '../../scss/templates/mainLayout.module.scss';
import { NoticeToast } from './Toast/NoticeToast';
import { BreadCrumbLayout } from './BreadCrumbLayout';
import { MarqueeLayout } from './MarqueeLayout';

type Props = {
  title: string;
};

export const MainLayout: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <main className={`${scss.wrapper} `}>
        {/* <MarqueeLayout /> */}
        <BreadCrumbLayout title={title} />
        {children}
        {/* <PageTopAnchor /> */}
        <div className={`${scss.footer_wrapper} `}>
          <footer className={scss.main_footer}>©Fancrew Inc.</footer>
        </div>
      </main>
      <NoticeToast />
    </>
  );
};
