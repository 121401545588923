import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { shopState } from '../states/atom/CommonPageState';
import { useParamClientAll } from './useParamClientAll';

export const useShop = () => {
  const { pathname, search } = useLocation();
  const allClient = useParamClientAll();
  const [shop, setShop] = useRecoilState(shopState(pathname + allClient));
  // URLにパラメータが無い場合
  if (shop === undefined || shop == null) {
    return null;
  }

  return shop;
};
