import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  UseFormRegister,
  DeepMap,
  DeepPartial,
  FieldError,
  UseFormHandleSubmit,
  UseFormSetValue,
} from 'react-hook-form';
import { InquieryApi, InquiryCategoryOutputResponse, InquryUpdateFormResponse } from '../../../api-client';
import scss from '../../../scss/organisms/inquiry-modal.module.scss';

type Props = {
  register: UseFormRegister<InquryUpdateFormResponse>;
  errors: DeepMap<DeepPartial<InquryUpdateFormResponse>, FieldError>;
  setValue: UseFormSetValue<InquryUpdateFormResponse>;
};

const InquiryForm: React.FC<Props> = ({ register, errors, setValue }) => {
  const [categories, setCategories] = useState<Array<InquiryCategoryOutputResponse>>([{ id: 0, title: '' }]);
  const api = new InquieryApi();

  useEffect(() => {
    api.inquieryCategory().then((res) => {
      setCategories(res.data);
    });
  }, []);

  useEffect(() => {
    setValue('inquiryCategoryId', 1);
  }, []);
  return (
    <>
      <div style={{ color: '#666666' }}>
        <Form.Group className="mb-4 d-block" controlId="name">
          <Form.Label style={{ fontWeight: '700' }}>名前</Form.Label>
          <Form.Control type="text" {...register('name')} className="w-100" autoComplete="off" />
          {errors.name && (
            <div className={scss.error} role="alert" data-testid="alert">
              {errors.name?.message}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-4 d-block" controlId="mailaddress">
          <Form.Label style={{ fontWeight: '700' }}>連絡先メールアドレス</Form.Label>
          <Form.Control type="text" {...register('mailaddress')} className="w-100" autoComplete="off" />
          {errors.mailaddress && (
            <div className={scss.error} role="alert" data-testid="alert">
              {errors.mailaddress?.message}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-4 d-block" controlId="telNumber">
          <Form.Label style={{ fontWeight: '700' }}>連絡先電話番号</Form.Label>
          <Form.Control type="text" {...register('telNumber')} className="w-100" autoComplete="off" />
          {errors.telNumber && (
            <div className={scss.error} role="alert" data-testid="alert">
              {errors.telNumber?.message}
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-4 d-block" controlId="category">
          <Form.Label style={{ fontWeight: '700' }}>問い合わせ区分</Form.Label>
          <Form.Select className="w-100" {...register('inquiryCategoryId')}>
            {categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.title}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-4 d-block" controlId="content">
          <Form.Label style={{ fontWeight: '700' }}>お問い合わせ内容</Form.Label>
          <Form.Control
            as="textarea"
            {...register('content')}
            rows={5}
            className="w-100"
            autoComplete="off"
            data-testid="content"
          />
        </Form.Group>
      </div>
    </>
  );
};

export default InquiryForm;
