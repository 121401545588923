import React from 'react';
import scss from '../../scss/templates/sidebarOverlay.module.scss';

type Props = {
  handleClick: () => void;
};

export const SidebarOverlay: React.FC<Props> = ({ handleClick }) => {
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  return <div className={scss.full_overlay} onClick={handleClick} onKeyPress={handleClick} />;
};
