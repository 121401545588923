import React from 'react';

import scss from '../../scss/atoms/title.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Title: React.FC<Props> = ({ children, ...rest }): JSX.Element => {
  return <h2 className={scss.main_title}>{children}</h2>;
};

Title.defaultProps = {
  className: undefined,
};
