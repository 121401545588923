import { atom } from 'recoil';
import { CurrentUser } from '../../interfaces/user';
import { RecoilAtomKeys } from '../RecoilKeys'; // ログインユーザの型定義

// undefined : まだログイン確認が完了していない状態とする
// null      : ログイン確認をした結果、ログインしていなかった状態とする
export const currentUserState = atom<undefined | null | CurrentUser>({
  key: RecoilAtomKeys.CURRENT_USER_STATE,
  default: null,
});
