import { atom, atomFamily } from 'recoil';
import { SingleValue } from 'react-select';
import { RecoilAtomKeys } from '../RecoilKeys';

export const sidebarState = atom({
  key: RecoilAtomKeys.SIDEBAR_STATE,
  default: true,
});

export const noticeToastState = atom<string | undefined>({
  key: RecoilAtomKeys.NOTICE_TOAST_STATE,
  default: undefined,
});

export const shopState = atomFamily<
  SingleValue<{
    value: string;
    label: string;
    isDisabled: boolean;
  }>,
  string
>({
  key: RecoilAtomKeys.SHOP_STATE,
  default: null,
});

export const ymState = atomFamily<string, string>({
  key: RecoilAtomKeys.YM_STATE,
  default: '',
});

export const billingYmState = atomFamily<string, string>({
  key: RecoilAtomKeys.BILLING_YM_STATE,
  default: '',
});

export const qscCategoryState = atomFamily<string, string>({
  key: RecoilAtomKeys.QSC_CATEGORY_STATE,
  default: '',
});

export const monitorState = atomFamily<string, string>({
  key: RecoilAtomKeys.MONITOR_STATE,
  default: '',
});

export const genreState = atomFamily<string, string>({
  key: RecoilAtomKeys.GENRE_STATE,
  default: '',
});

export const giftState = atomFamily<string, string>({
  key: RecoilAtomKeys.GIFT_STATE,
  default: '',
});

export const giftStatusState = atomFamily<string, string>({
  key: RecoilAtomKeys.GIFT_STATUS_STATE,
  default: '',
});

export const goalProgressState = atomFamily<string, string>({
  key: RecoilAtomKeys.GOAL_PROGRESS_STATE,
  default: '',
});

export const goalBeforeDiffState = atomFamily<string, string>({
  key: RecoilAtomKeys.GOAL_BEFORE_DIFF_STATE,
  default: '',
});

export const goalAfterDiffState = atomFamily<string, string>({
  key: RecoilAtomKeys.GOAL_AFTER_DIFF_STATE,
  default: '',
});

export const movingAverageTypeState = atomFamily<string, string>({
  key: RecoilAtomKeys.MOVING_AVERAGE_TYPE_STATE,
  default: '1',
});

export const movingAveragePeriodState = atomFamily<string, string>({
  key: RecoilAtomKeys.MOVING_AVERAGE_PERIOD_STATE,
  default: '6',
});

export const achievementRateFormatTypeState = atomFamily<string, string>({
  key: RecoilAtomKeys.ACHIEVEMENT_RATE_FORMAT_TYPE_STATE,
  default: '1',
});

export const allClientState = atomFamily<string, string>({
  key: RecoilAtomKeys.ALL_CLIENT_STATE,
  default: '',
});

export const staffCommentState = atomFamily<string, string>({
  key: RecoilAtomKeys.STAFF_COMMENT_STATE,
  default: '',
});
export const bussinessState = atomFamily<string, string>({
  key: RecoilAtomKeys.BUSSINESS_STATE,
  default: '',
});

export const areaState = atomFamily<string, string>({
  key: RecoilAtomKeys.AREA_STATE,
  default: '',
});

export const priceState = atomFamily<{ priceMin: number; priceMax: number }, string>({
  key: RecoilAtomKeys.PRICE_STATE,
  default: { priceMin: 0, priceMax: 0 },
});

export const salesPersonState = atomFamily<number | undefined, string>({
  key: RecoilAtomKeys.SALES_PERSONE_STATE,
  default: 0,
});
export const inquiryStatusState = atomFamily<number | undefined, string>({
  key: RecoilAtomKeys.INQUIRY_STATUS_STATE,
  default: 0,
});

export const enqueteState = atomFamily<string, string>({
  key: RecoilAtomKeys.ENQUETE_STATE,
  default: '',
});

export const openDownLoadModalState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.OPEN_DOWNLOAD_MODAL_STATE,
  default: false,
});

export const downloadState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.DOWNLOAD_STATE,
  default: false,
});

export const excelDataState = atomFamily<string, string>({
  key: RecoilAtomKeys.EXCEL_DATA_STATE,
  default: '',
});

export const applyState = atomFamily<string, string>({
  key: RecoilAtomKeys.APPLY_STATE,
  default: '',
});

export const selectShopListState = atom<string[]>({
  key: RecoilAtomKeys.SELECT_SHOP_LIST_STATE,
  default: [],
});
export const userNameState = atomFamily<string, string>({
  key: RecoilAtomKeys.USER_NAME_STATE,
  default: '',
});

export const userMainSelectResetFlgState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.USER_MAIN_SELECT_RESET_STATE,
  default: false,
});

export const userSubSelectResetFlgState = atomFamily<boolean, string>({
  key: RecoilAtomKeys.USER_SUB_SELECT_RESET_STATE,
  default: false,
});

export const mailAddlessState = atomFamily<string, string>({
  key: RecoilAtomKeys.MAIL_ADDLESS_STATE,
  default: '',
});

export const userBelongState = atomFamily<string, string>({
  key: RecoilAtomKeys.USER_BELONG_STATE,
  default: '',
});

export const billingDestinationState = atomFamily<string, string>({
  key: RecoilAtomKeys.BILLING_DESTINATION_STATE,
  default: '',
});

export const roiAdminSelectClientState = atomFamily<
  { roiAdminSelectClientId: string | undefined | null; roiAdminSelectClientName: string | undefined | null },
  string
>({
  key: RecoilAtomKeys.ROI_ADMIN_SELECT_CLIENT_STATE,
  default: { roiAdminSelectClientId: null, roiAdminSelectClientName: null },
});
