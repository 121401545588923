import React, { useLayoutEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { currentUserState } from '../../states/atom/CurrentUserState';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { getCurrentJwt, getUser, logout } from '../../services/AuthService';
import { TokenObj } from '../../interfaces/TokenObj';
import { LoginLayout } from '../templates/LoginLayout';
import { Url } from '../../constants/Url';

type Props = {
  path: string;
  title: string;
  exact: boolean;
};

export const GuestRoute: React.FC<Props> = ({ path, title, exact, children }) => {
  const setCurrentUser = useSetRecoilState(currentUserState);
  const { currentUser, isAuthChecking } = useCurrentUser();
  const resetCurrentUser = useResetRecoilState(currentUserState);
  const history = useHistory();
  // JWT情報取得
  const jwtData: TokenObj | boolean = getCurrentJwt();
  useLayoutEffect(() => {
    // JWTが設定されていれば
    if (jwtData !== false) {
      // 認証されているかチェックする
      getUser()
        .then((result) => {
          if (typeof result === 'object') {
            setCurrentUser(result);
            history.push(Url.SCORE_VIEW);
          } else {
            resetCurrentUser();
            logout();
          }
        })
        .catch(() => {
          resetCurrentUser();
          logout();
          history.push(Url.COMMON_ERROR);
        });
    } else {
      resetCurrentUser();
    }
  }, []);
  // 既に認証されていれば、表示したいページにリダイレクト
  return (
    <>
      {!isAuthChecking ? (
        <LoginLayout title={title}>
          <Route path={path} exact={exact}>
            {children}
          </Route>
        </LoginLayout>
      ) : null}
    </>
  );
};
