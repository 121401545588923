import React from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Url } from '../../constants/Url';
import { shopState } from '../../states/atom/CommonPageState';
import scss from '../../scss/templates/loginLayout.module.scss';
import layoutScss from '../../scss/templates/layout.module.scss';
import { useShop } from '../../hooks/useShop';

type Props = {
  title: string;
};

export const BreadCrumbLayout: React.FC<Props> = ({ title }) => {
  const shop = useShop();
  const history = useHistory();
  const handleTransition = (url: string) => {
    history.push(url);
  };
  return (
    <Row className={`${layoutScss.breadcrumb_layout}`}>
      {/** ReactのBreadcrumbだと、marginBottom指定ができないので、olタグに直接指定する */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb" style={{ marginBottom: '0px', flexWrap: 'nowrap' }}>
          <Breadcrumb.Item onClick={() => handleTransition(Url.SCORE_VIEW)}>Home</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {shop && shop?.label}：{title}
          </Breadcrumb.Item>
        </ol>
      </nav>
    </Row>
  );
};
