import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import scss from '../../scss/pages/initpass.module.scss';
import { Url } from '../../constants/Url';
import { Alert } from '../atoms/Alert';
import layoutScss from '../../scss/templates/mainLayout.module.scss';
import { ConfirmModal } from '../organisms/ConfirmModal';
import { openConfirmModal } from '../../states/atom/ConfirmModalOpen';
import { AccountApi, PasswordResetUpdateFormResponse, PasswordResetUpdateOutputResponse } from '../../api-client';
import { useUrlParams } from '../../hooks/useUrlParams';

const PassResetPage: React.FC = () => {
  const api = new AccountApi();
  const { currentUser, isAuthChecking } = useCurrentUser();
  // パスワード表示非表示制御
  const [isRevealNewPassword, setIsRevealNewPassword] = useState(false);
  const [isRevealConfPassword, setIsRevealConfPassword] = useState(false);
  // 処理結果
  const [updateResult, setUpdateResult] = useState<boolean>(true);
  const params = useUrlParams();
  // モーダル
  const [open, setOpen] = useRecoilState(openConfirmModal);
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
  } = useForm<PasswordResetUpdateFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
  });
  // React-Router-Domの機能
  const history = useHistory();

  const toggleNewPassword = () => {
    setIsRevealNewPassword((prevState) => !prevState);
  };
  const toggleConfPassword = () => {
    setIsRevealConfPassword((prevState) => !prevState);
  };

  useEffect(() => {
    // 既に初期パスワードを変更済み
    if (params.token === '') {
      history.push(Url.LOGIN);
    }
  }, []);

  const onSubmit: SubmitHandler<PasswordResetUpdateFormResponse> = async (data) => {
    await api.passwordResetUpdate(data).then(async (res) => {
      if (res != null) {
        const resultRes = res.data as PasswordResetUpdateOutputResponse;
        // エラーが無かった場合
        if (!resultRes.result) {
          setUpdateResult(false);
        } else {
          setOpen(true);
        }
      }
    });
  };

  return (
    <>
      {!isAuthChecking ? (
        <div
          className={`${scss.result_group} ${layoutScss.more_narrow} ${scss.content_under_margin} ${scss.content_top_margin}`}
        >
          <Card className={`${scss.top_lg_space}`}>
            <Card.Body>
              <h3 className={`${scss.simple_h2}`}>パスワード変更</h3>
              <p className={`${scss.text_p} ${scss.text_bt_mg}`}>
                パスワードの変更画面です。
                <br />
                パスワードは8文字以上で、使用できる文字は英数字(A-Z、a-z、0-9)です。
                <br />
                また必ず英字、数字それぞれ1文字以上含んでいる必要があります。
                <br />
                ※新しいパスワードは次回ログイン時に使用しますので、お忘れにならないようご注意ください。
              </p>
              {!updateResult && (
                <div>
                  <Alert variant="danger">
                    <span>パスワードの変更に失敗しました。</span>
                  </Alert>
                </div>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Form.Control {...register('token')} type="hidden" value={params.token} />
                <div>
                  <div className={`${scss.value_wrapper} `}>
                    <p className={`${scss.label}`}>新しいパスワード</p>
                    <div className={`${scss.block_relative}`}>
                      <Form.Control
                        {...register('password', {
                          required: {
                            value: true,
                            message: '新しいパスワードは必須です。',
                          },
                          // pattern: { value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/, message: 'パスワードの形式が間違っています'},
                          pattern: {
                            value: /^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,32}$/,
                            message: 'パスワードの形式が間違っています',
                          },
                          minLength: {
                            value: 8,
                            message: '8文字以上入力してください。',
                          },
                        })}
                        type={isRevealNewPassword ? 'text' : 'password'}
                        className={`form-control ${errors.password && 'is-invalid'}`}
                      />
                      <span onClick={toggleNewPassword} role="presentation" className={scss.password_reveal}>
                        {isRevealNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    {errors.password && (
                      <div>
                        <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
                          <span>{errors.password.message}</span>
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className={`${scss.value_wrapper}`}>
                    <p className={`${scss.label}`}>確認パスワード</p>
                    <div className={`${scss.block_relative}`}>
                      <Form.Control
                        {...register('confirmPassword', {
                          required: {
                            value: true,
                            message: '確認パスワードは必須です。',
                          },
                          validate: (value) => {
                            return value === getValues('password') || 'パスワードが一致しません';
                          },
                        })}
                        type={isRevealConfPassword ? 'text' : 'password'}
                        className={`form-control ${errors.confirmPassword && 'is-invalid'}`}
                      />
                      <span onClick={toggleConfPassword} role="presentation" className={scss.password_reveal}>
                        {isRevealConfPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <div>
                        <Alert styleName={`${scss.alert_mini_padding} ${scss.m_b_15} ${scss.m_t_15}`} variant="danger">
                          <span>{errors.confirmPassword.message}</span>
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className={`${scss.btn_shadow}`}>
                    <Button className={`${scss.button} ${scss.action_slow} ${scss.btn_shadow}`} type="submit">
                      変更
                    </Button>
                  </div>
                </div>
              </form>
            </Card.Body>
          </Card>
        </div>
      ) : null}
      <ConfirmModal title="パスワード変更" content="パスワードの変更が完了しました。" url={Url.LOGIN} />
    </>
  );
};

export default PassResetPage;
