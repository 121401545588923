/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingInvoiceDownloadOutputResponse } from '../domains';
// @ts-ignore
import { BillingListOutputResponse } from '../domains';
// @ts-ignore
import { BillingYmListOutputResponse } from '../domains';
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingList: async (ym?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書年月一覧を取得する
         * @summary 請求書年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingYmList: async (ym?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-Ym-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書をダウンロードする
         * @summary 請求書ダウンロード
         * @param {number} billingDestinationId 
         * @param {boolean} item 
         * @param {boolean} shop 
         * @param {boolean} monitor 
         * @param {boolean} apply 
         * @param {number} extension 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobBillingInvoiceDownload: async (billingDestinationId: number, item: boolean, shop: boolean, monitor: boolean, apply: boolean, extension: number, ym?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationId' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'billingDestinationId', billingDestinationId)
            // verify required parameter 'item' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'item', item)
            // verify required parameter 'shop' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'shop', shop)
            // verify required parameter 'monitor' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'monitor', monitor)
            // verify required parameter 'apply' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'apply', apply)
            // verify required parameter 'extension' is not null or undefined
            assertParamExists('tobBillingInvoiceDownload', 'extension', extension)
            const localVarPath = `/api/billing-invoice-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingDestinationId !== undefined) {
                localVarQueryParameter['billingDestinationId'] = billingDestinationId;
            }

            if (item !== undefined) {
                localVarQueryParameter['item'] = item;
            }

            if (shop !== undefined) {
                localVarQueryParameter['shop'] = shop;
            }

            if (monitor !== undefined) {
                localVarQueryParameter['monitor'] = monitor;
            }

            if (apply !== undefined) {
                localVarQueryParameter['apply'] = apply;
            }

            if (extension !== undefined) {
                localVarQueryParameter['extension'] = extension;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingList(ym?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingList(ym, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書年月一覧を取得する
         * @summary 請求書年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingYmList(ym?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingYmListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingYmList(ym, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書をダウンロードする
         * @summary 請求書ダウンロード
         * @param {number} billingDestinationId 
         * @param {boolean} item 
         * @param {boolean} shop 
         * @param {boolean} monitor 
         * @param {boolean} apply 
         * @param {number} extension 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tobBillingInvoiceDownload(billingDestinationId: number, item: boolean, shop: boolean, monitor: boolean, apply: boolean, extension: number, ym?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tobBillingInvoiceDownload(billingDestinationId, item, shop, monitor, apply, extension, ym, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {string} [ym] 年月
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingList(ym?: string, allClient?: string, options?: any): AxiosPromise<Array<BillingListOutputResponse>> {
            return localVarFp.billingList(ym, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書年月一覧を取得する
         * @summary 請求書年月一覧取得
         * @param {string} [ym] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingYmList(ym?: string, allClient?: string, options?: any): AxiosPromise<BillingYmListOutputResponse> {
            return localVarFp.billingYmList(ym, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書をダウンロードする
         * @summary 請求書ダウンロード
         * @param {number} billingDestinationId 
         * @param {boolean} item 
         * @param {boolean} shop 
         * @param {boolean} monitor 
         * @param {boolean} apply 
         * @param {number} extension 
         * @param {string} [ym] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tobBillingInvoiceDownload(billingDestinationId: number, item: boolean, shop: boolean, monitor: boolean, apply: boolean, extension: number, ym?: string, options?: any): AxiosPromise<BillingInvoiceDownloadOutputResponse> {
            return localVarFp.tobBillingInvoiceDownload(billingDestinationId, item, shop, monitor, apply, extension, ym, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 請求書一覧を取得する
     * @summary 請求書一覧取得
     * @param {string} [ym] 年月
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingList(ym?: string, allClient?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingList(ym, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書年月一覧を取得する
     * @summary 請求書年月一覧取得
     * @param {string} [ym] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingYmList(ym?: string, allClient?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingYmList(ym, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書をダウンロードする
     * @summary 請求書ダウンロード
     * @param {number} billingDestinationId 
     * @param {boolean} item 
     * @param {boolean} shop 
     * @param {boolean} monitor 
     * @param {boolean} apply 
     * @param {number} extension 
     * @param {string} [ym] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public tobBillingInvoiceDownload(billingDestinationId: number, item: boolean, shop: boolean, monitor: boolean, apply: boolean, extension: number, ym?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).tobBillingInvoiceDownload(billingDestinationId, item, shop, monitor, apply, extension, ym, options).then((request) => request(this.axios, this.basePath));
    }
}

