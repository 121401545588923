import Cookies from 'js-cookie';
import { NEW_CLOUD } from '../Constants';

export const useNewCloud = () => {
  const newCloud = Cookies.get(NEW_CLOUD);
  let newMode = false;
  let cloudMode = false;
  if (newCloud) {
    newMode = newCloud === 'false'; // 新基盤
    cloudMode = newCloud === 'true'; // クラウド
  }
  return {
    newMode,
    cloudMode,
  };
};
