import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import { UnitButton } from '../../atoms/Button/UnitButton';
import scss from '../../../scss/organisms/registerUser.module.scss';

export const RegistUserForm: React.VFC = () => {
  const history = useHistory();
  const handleTransition = () => {
    history.push(Url.USER_LIST);
  };
  return (
    <Form>
      <Form.Group as={Row} className=" mb-1">
        <Col sm="2" className={` ${scss.pc_none}`}>
          <UnitButton text="キャンセル" onClick={handleTransition} />
        </Col>
      </Form.Group>
    </Form>
  );
};
