/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew ToB向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CloudScoreCategoryOutputResponse } from '../domains';
// @ts-ignore
import { CloudScoreSmallCategoryOutputResponse } from '../domains';
// @ts-ignore
import { ScoreCategoryAllAvgOutputResponse } from '../domains';
// @ts-ignore
import { ScoreCategoryOutputResponse } from '../domains';
// @ts-ignore
import { ScoreCommentCategoryOutputResponse } from '../domains';
// @ts-ignore
import { ScoreShopByMonthOutputResponse } from '../domains';
// @ts-ignore
import { TopCategoryScoreOutputResponse } from '../domains';
/**
 * ScoreApi - axios parameter creator
 * @export
 */
export const ScoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得する
         * @summary クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreCategory: async (id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, baseCat?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cloud-score-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (ancestorCategoryId !== undefined) {
                localVarQueryParameter['ancestorCategoryId'] = ancestorCategoryId;
            }

            if (baseCat !== undefined) {
                localVarQueryParameter['baseCat'] = baseCat;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得する
         * @summary クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreSmallCategory: async (id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/cloud-score-small-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (baseCat !== undefined) {
                localVarQueryParameter['baseCat'] = baseCat;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント/組織/店舗の設問カテゴリスコア取得する
         * @summary クライアント/組織/店舗の設問カテゴリスコア取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 上位カテゴリID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCategory: async (id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/score-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (ancestorCategoryId !== undefined) {
                localVarQueryParameter['ancestorCategoryId'] = ancestorCategoryId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 全体の平均設問カテゴリスコア取得する
         * @summary 全体の平均設問カテゴリスコア取得
         * @param {string} [ym] 年月
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCategoryAllAvg: async (ym?: string, id?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/score-category-all-avg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 点数傾向コメント表示を取得する
         * @summary 点数傾向コメント表示取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCommentCategory: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/score-comment-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スコア年月を取得する
         * @summary スコア年月取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreShopByMonth: async (id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/score-shop-by-month`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (averageType !== undefined) {
                localVarQueryParameter['averageType'] = averageType;
            }

            if (averagePeriod !== undefined) {
                localVarQueryParameter['averagePeriod'] = averagePeriod;
            }

            if (rateFormatType !== undefined) {
                localVarQueryParameter['rateFormatType'] = rateFormatType;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreShopByMonthExcel: async (id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/score-shop-by-month-excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (averageType !== undefined) {
                localVarQueryParameter['averageType'] = averageType;
            }

            if (averagePeriod !== undefined) {
                localVarQueryParameter['averagePeriod'] = averagePeriod;
            }

            if (rateFormatType !== undefined) {
                localVarQueryParameter['rateFormatType'] = rateFormatType;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} id 
         * @param {string} ym 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreTransitionDownload: async (id: string, ym: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('scoreTransitionDownload', 'id', id)
            // verify required parameter 'ym' is not null or undefined
            assertParamExists('scoreTransitionDownload', 'ym', ym)
            const localVarPath = `/api/score-transition-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 上位カテゴリスコアを取得する
         * @summary 上位カテゴリスコア取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topCategoryScore: async (id?: string, ym?: string, monitorId?: string, allClient?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/top-category-score`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (ym !== undefined) {
                localVarQueryParameter['ym'] = ym;
            }

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (allClient !== undefined) {
                localVarQueryParameter['allClient'] = allClient;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScoreApi - functional programming interface
 * @export
 */
export const ScoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScoreApiAxiosParamCreator(configuration)
    return {
        /**
         * クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得する
         * @summary クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudScoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudScoreCategoryOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudScoreCategory(id, ym, monitorId, ancestorCategoryId, baseCat, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得する
         * @summary クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cloudScoreSmallCategory(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CloudScoreSmallCategoryOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cloudScoreSmallCategory(id, ym, monitorId, baseCat, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント/組織/店舗の設問カテゴリスコア取得する
         * @summary クライアント/組織/店舗の設問カテゴリスコア取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 上位カテゴリID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoreCategoryOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreCategory(id, ym, monitorId, ancestorCategoryId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 全体の平均設問カテゴリスコア取得する
         * @summary 全体の平均設問カテゴリスコア取得
         * @param {string} [ym] 年月
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreCategoryAllAvg(ym?: string, id?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScoreCategoryAllAvgOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreCategoryAllAvg(ym, id, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 点数傾向コメント表示を取得する
         * @summary 点数傾向コメント表示取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreCommentCategory(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoreCommentCategoryOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreCommentCategory(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スコア年月を取得する
         * @summary スコア年月取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreShopByMonth(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoreShopByMonthOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreShopByMonth(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreShopByMonthExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreShopByMonthExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} id 
         * @param {string} ym 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async scoreTransitionDownload(id: string, ym: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.scoreTransitionDownload(id, ym, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 上位カテゴリスコアを取得する
         * @summary 上位カテゴリスコア取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topCategoryScore(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopCategoryScoreOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topCategoryScore(id, ym, monitorId, allClient, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScoreApi - factory interface
 * @export
 */
export const ScoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScoreApiFp(configuration)
    return {
        /**
         * クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得する
         * @summary クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, baseCat?: string, allClient?: string, options?: any): AxiosPromise<CloudScoreCategoryOutputResponse> {
            return localVarFp.cloudScoreCategory(id, ym, monitorId, ancestorCategoryId, baseCat, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得する
         * @summary クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得API
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [baseCat] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cloudScoreSmallCategory(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: any): AxiosPromise<CloudScoreSmallCategoryOutputResponse> {
            return localVarFp.cloudScoreSmallCategory(id, ym, monitorId, baseCat, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント/組織/店舗の設問カテゴリスコア取得する
         * @summary クライアント/組織/店舗の設問カテゴリスコア取得
         * @param {string} [id] クライアント,組織,店舗ID
         * @param {string} [ym] 年月
         * @param {string} [monitorId] モニターベースID
         * @param {string} [ancestorCategoryId] 上位カテゴリID
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, allClient?: string, options?: any): AxiosPromise<ScoreCategoryOutputResponse> {
            return localVarFp.scoreCategory(id, ym, monitorId, ancestorCategoryId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 全体の平均設問カテゴリスコア取得する
         * @summary 全体の平均設問カテゴリスコア取得
         * @param {string} [ym] 年月
         * @param {string} [id] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCategoryAllAvg(ym?: string, id?: string, allClient?: string, options?: any): AxiosPromise<Array<ScoreCategoryAllAvgOutputResponse>> {
            return localVarFp.scoreCategoryAllAvg(ym, id, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 点数傾向コメント表示を取得する
         * @summary 点数傾向コメント表示取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreCommentCategory(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<ScoreCommentCategoryOutputResponse> {
            return localVarFp.scoreCommentCategory(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * スコア年月を取得する
         * @summary スコア年月取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreShopByMonth(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: any): AxiosPromise<ScoreShopByMonthOutputResponse> {
            return localVarFp.scoreShopByMonth(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [averageType] 
         * @param {string} [averagePeriod] 
         * @param {string} [rateFormatType] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreShopByMonthExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.scoreShopByMonthExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * スコア年間推移を取得する
         * @summary スコア年間推移Excel
         * @param {string} id 
         * @param {string} ym 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        scoreTransitionDownload(id: string, ym: string, allClient?: string, options?: any): AxiosPromise<string> {
            return localVarFp.scoreTransitionDownload(id, ym, allClient, options).then((request) => request(axios, basePath));
        },
        /**
         * 上位カテゴリスコアを取得する
         * @summary 上位カテゴリスコア取得
         * @param {string} [id] 
         * @param {string} [ym] 
         * @param {string} [monitorId] 
         * @param {string} [allClient] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topCategoryScore(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: any): AxiosPromise<TopCategoryScoreOutputResponse> {
            return localVarFp.topCategoryScore(id, ym, monitorId, allClient, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScoreApi - object-oriented interface
 * @export
 * @class ScoreApi
 * @extends {BaseAPI}
 */
export class ScoreApi extends BaseAPI {
    /**
     * クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得する
     * @summary クラウドCSクライアント/組織/店舗の(OP,BRスコア)取得API
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターベースID
     * @param {string} [ancestorCategoryId] 
     * @param {string} [baseCat] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public cloudScoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).cloudScoreCategory(id, ym, monitorId, ancestorCategoryId, baseCat, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得する
     * @summary クラウドCSクライアント/組織/店舗の(小設問カテゴリ)取得API
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターベースID
     * @param {string} [baseCat] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public cloudScoreSmallCategory(id?: string, ym?: string, monitorId?: string, baseCat?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).cloudScoreSmallCategory(id, ym, monitorId, baseCat, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント/組織/店舗の設問カテゴリスコア取得する
     * @summary クライアント/組織/店舗の設問カテゴリスコア取得
     * @param {string} [id] クライアント,組織,店舗ID
     * @param {string} [ym] 年月
     * @param {string} [monitorId] モニターベースID
     * @param {string} [ancestorCategoryId] 上位カテゴリID
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreCategory(id?: string, ym?: string, monitorId?: string, ancestorCategoryId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreCategory(id, ym, monitorId, ancestorCategoryId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 全体の平均設問カテゴリスコア取得する
     * @summary 全体の平均設問カテゴリスコア取得
     * @param {string} [ym] 年月
     * @param {string} [id] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreCategoryAllAvg(ym?: string, id?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreCategoryAllAvg(ym, id, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 点数傾向コメント表示を取得する
     * @summary 点数傾向コメント表示取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreCommentCategory(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreCommentCategory(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スコア年月を取得する
     * @summary スコア年月取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [averageType] 
     * @param {string} [averagePeriod] 
     * @param {string} [rateFormatType] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreShopByMonth(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreShopByMonth(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スコア年間推移を取得する
     * @summary スコア年間推移Excel
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [averageType] 
     * @param {string} [averagePeriod] 
     * @param {string} [rateFormatType] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreShopByMonthExcel(id?: string, ym?: string, monitorId?: string, averageType?: string, averagePeriod?: string, rateFormatType?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreShopByMonthExcel(id, ym, monitorId, averageType, averagePeriod, rateFormatType, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * スコア年間推移を取得する
     * @summary スコア年間推移Excel
     * @param {string} id 
     * @param {string} ym 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public scoreTransitionDownload(id: string, ym: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).scoreTransitionDownload(id, ym, allClient, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 上位カテゴリスコアを取得する
     * @summary 上位カテゴリスコア取得
     * @param {string} [id] 
     * @param {string} [ym] 
     * @param {string} [monitorId] 
     * @param {string} [allClient] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoreApi
     */
    public topCategoryScore(id?: string, ym?: string, monitorId?: string, allClient?: string, options?: AxiosRequestConfig) {
        return ScoreApiFp(this.configuration).topCategoryScore(id, ym, monitorId, allClient, options).then((request) => request(this.axios, this.basePath));
    }
}

