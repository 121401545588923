import React, { useLayoutEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { noticeToastState } from '../../../states/atom/CommonPageState';
import scss from '../../../scss/organisms/notice-toast.module.scss';

export const NoticeToast: React.FC = () => {
  const [show, setShow] = useState(false);
  const [notice, setNotice] = useRecoilState(noticeToastState);

  useLayoutEffect(() => {
    if (notice) {
      setShow(true);
      setTimeout(() => {
        setNotice(undefined);
      }, 3000);
    }
  }, [notice]);

  const date = new Date();
  const hours = `0${date.getHours()}`.slice(-2); // ゼロパディング
  const minutes = `0${date.getMinutes()}`.slice(-2);
  return (
    <>
      <ToastContainer className={`p-3 ${scss.wrapper}`}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide animation>
          <Toast.Header className={`${scss.header}`}>
            <strong className="me-auto">通知</strong>
            <small className="text-muted">
              {hours}:{minutes}
            </small>
          </Toast.Header>
          <Toast.Body>{notice}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
