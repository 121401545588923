import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { Url } from '../../constants/Url';
import scss from '../../scss/pages/error.module.scss';

const NoDataPage: React.FC = () => {
  const history = useHistory();

  return (
    <Container fluid className={scss.base}>
      <div className="mb-4 text-center m-auto">
        <h3 style={{ fontWeight: '500' }}>データが存在しません</h3>
      </div>
      <div className="mb-5 text-center">
        ご不便をおかけして大変申し訳ございません。
        <br />
        現在表示できるデータが存在しておりません。
      </div>
      <div className="d-flex justify-content-center m-auto">
        <Button className={scss.back_button} variant="warning" size="lg" onClick={() => history.push(Url.LOGOUT)}>
          ログアウトする
        </Button>
      </div>
    </Container>
  );
};

export default NoDataPage;
