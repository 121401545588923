export const Url = {
  ROOT: '/',
  LOGIN: '/login',
  PASSWORD_FORGET: '/password-forget',
  PASSWORD_RESET_MAIL_COMPLETE: '/password-reset-mail-complete',
  PASSWORD_RESET: '/password-reset',
  MY_PAGE: '/my',
  CLIENT_SELECT: '/client-select',
  LOGOUT: '/logout',
  INIT_PASSWORD: '/init-pass',
  COMMON_ERROR: '/error',
  NO_DATA: '/no-data',
  ACCESS_DENY: '/access-deny',
  MAINTENANCE: '/maintenance',
  REGISTER_USER: '/register-user',
  USER_LIST: '/user-list',
  SCORE_SHOP_BY_MONTH: '/score-shop-by-month',
  COMMENT_LIST: '/comment-list',
  STAFF_COMMENT_LIST: '/staff-comment-list',
  EDIT_ACCOUNT: '/edit-account',
  EDIT_USER: '/edit-user',
  DIGITAL_TICKET_LIST: '/digital-ticket-list',
  SCORE_VIEW: '/score-view',
  MONITOR_PROGRESS: '/monitor-progress',
  SHOP_CATEGORY_GOAL_RESULT: '/shop-category-goal-result',
  SHOP_CATEGORY_GOAL_SETTING: '/shop-category-goal-setting',
  SHOP_GOAL_RESULT: '/shop-goal-result',
  ANSWER_DISTRIBUTION: '/answer-distribution',
  CLIENT_IN_SHOP_RANKING: '/client-in-shop-ranking',
  IMAGE: '/image',
  IMAGE_VIEW: '/image-view',
  ENQUETE: '/enquete',
  ENQUETE_DESIGN: '/enquate-design',
  CONFIRM_CONTRACT: '/confirm-contract',
  NATIONAL_SHOP_RANKING: '/national-shop-ranking',
  INQUIRY_LIST: '/inquiry-list',
  INQUIRY_DETAIL: '/inquiry-detail/:id',
  INVOICE_LIST: '/invoice-list',
  IMPROVE_QUESTION: '/improve-question',
  SHOP_GOAL_SETTING: '/shop-goal-setting',
  NEWS_LIST: '/news-list',
  REGISTER_NEWS: '/register-news',
  EDIT_NEWS: '/edit-news',
  LIBRARY: '/library',
  CS_SCORE: '/cs-score',
} as const;
