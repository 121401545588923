import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React from 'react';

/**
 * Base64からファイル変換
 * @param base64
 * @param type
 */
export const toBlob = (base64: string, type: string) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i += 1) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try {
    return new Blob([buffer.buffer], {
      type,
    });
  } catch (e) {
    return false;
  }
};

// interface UrlParamValue {
//   id: string;
//   ym: string;
//   monitor: string;
// }

/**
 * URLからクエリパラメータを取得する（userUrlParamsに移動した）
 *
 * @param search
 */
// export const urlParams = (search: string) => {
//   let params: UrlParamValue = {
//     id: '',
//     ym: '',
//     monitor: '',
//   };
//   // URLパラメータを文字列で取得(?含む)
//   let urlParamStr = search;
//   if (urlParamStr) {
//     // ?を除去
//     urlParamStr = urlParamStr.substring(1);
//     // urlパラメータをオブジェクトにまとめる
//     urlParamStr.split('&').forEach((param) => {
//       const temp = param.split('=');
//       // pramsオブジェクトにパラメータを追加
//       params = {
//         ...params,
//         [temp[0]]: temp[1],
//       };
//     });
//   }
//   return params;
// };

/**
 * クエリパラメータを作成する（カスタムフックに移動したいがエラーになる）
 *
 * @param search
 * @param key
 * @param value
 */
export const urlParamConv = (search: string, key: string, value: string, adminFlg?: boolean) => {
  let result = '';
  // URLパラメータを文字列で取得(?含む)
  let urlParamStr = search;
  if (urlParamStr) {
    // ?を除去
    urlParamStr = urlParamStr.substring(1);
    // urlパラメータをオブジェクトにまとめる
    urlParamStr.split('&').forEach((param) => {
      const temp = param.split('=');
      if (temp[0] === key && value === '') return; // continue
      // 管理者セレクトの場合その他のパラメータは除外
      if (temp[0] !== 'allClient' && adminFlg) return; // continue
      // pramsオブジェクトにパラメータを追加
      if (result === '') {
        result = temp[0] === key ? `?${key}=${value}` : `?${param}`;
      } else {
        result += temp[0] === key ? `&${key}=${value}` : `&${param}`;
      }
    });
    if (result.indexOf(key) === -1 && value !== '') {
      result += `&${key}=${value}`;
    }
  } else if (value !== '') {
    result = `?${key}=${value}`;
  }
  return result;
};

export type UrlParamType = {
  [key: string]: string | undefined;
};

/**
 * クエリパラメータを作成する（連想配列による複数対応）
 *
 * @param search
 * @param key
 * @param value
 */
export const urlParamConvArray = (search: string, params: UrlParamType) => {
  let result = search;
  Object.keys(params).map((key) => {
    const value = params[key];
    result = urlParamConv(search, key, value || '');
  });
  return result;
};

/**
 * クエリパラメータを作成する（連想配列による複数対応）
 *
 * @param search
 * @param key
 * @param value
 */
export const convBeforeScore = (diffScore?: string) => {
  let result: number;
  if (diffScore) {
    result = parseFloat(diffScore);
    if (typeof result === 'number' && !Number.isNaN(result)) {
      return result.toFixed(1);
    }
    return '-';
  }
  return '-';
};

export const fileDownLoad = (blob: Blob, filename?: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  if (filename === undefined) {
    window.open(link.href);
  } else {
    link.setAttribute('download', filename);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};
export const handlePrintPdf = <T extends HTMLElement>(ref: React.RefObject<T>, fileName: string) => {
  const element = ref.current;
  if (element === null) return;

  if (!element) throw Error('divRef is not assigned');
  html2canvas(element, {
    scale: 2,
  }).then((canvas) => {
    // eslint-disable-next-line new-cap
    const pdf = new jsPDF('l', 'px', 'a4');
    const imgData = canvas.toDataURL('image/png');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    // レスポンシブに対応
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const height = imgHeight * ratio;
    // base64エンコードされた画像URL、ファイルの認識に失敗した際のファイル形式、PDFドキュメント開始X座標、PDFドキュメント開始Y座標、画像の幅、画像の高さ
    pdf.addImage(imgData, 'png', 0, 10, pdfWidth - 5, height);
    pdf.save(`${fileName}.pdf`);
  });
};
